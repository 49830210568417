import React, { Fragment, useContext, useEffect, useState } from "react";
import ConexContext from "../context/conex/ConexContext";
import { useHotkeys } from "react-hotkeys-hook";

import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as CamposIcon } from "../assets/img/layout.svg";

// import trash from "../assets/img/iconos/acae_trash.svg"
// import trashwhite from "../assets/img/trash_white.svg"
// import trash from "../assets/img/iconos/acae_trash.svg"

export const ModalCampos = ({ show, setShow, conf, setConf, campos }) => {
	const { loc } = useContext(ConexContext); //contexto
	const [copyConf, setCopyConf] = useState(null); //copia del conf para poder modificarlo sin afectar al original
	//opciones de agregaciones posibles
	const aggs = [
		{
			nombre: "Suma",
			funcAgregado: "sum",
		},
		{
			nombre: "Máximo",
			funcAgregado: "max",
		},
		{
			nombre: "Mínimo",
			funcAgregado: "min",
		},
		{
			nombre: "Media",
			funcAgregado: "avg",
		},
	];

	/**
	 * Si se muestra el modal y no hay copia del conf, se crea
	 */
	useEffect(() => {
		if (show && !copyConf) setCopyConf(conf);
	}, [show, copyConf, conf]);

	// useEffect(() => {
	// 	console.log('copyConf', copyConf)
	// }, [copyConf])

	/**
	 * Guarda el modal
	 */
	const guarda = () => {
		setConf(copyConf);
		cierra();
	};
	/**
	 * Cierra el modal
	 */
	const cierra = () => {
		setCopyConf(null);
		setShow(false);
	};

	const formatearPrefijo = (cadena) => {
		if (cadena.startsWith("max.")) {
			return "Máximo ";
		} else if (cadena.startsWith("min.")) {
			return "Mínimo ";
		} else if (cadena.startsWith("sum.")) {
			return "Suma de ";
		} else if (cadena.startsWith("avg.")) {
			return "Media de ";
		} else {
			return "";
		}
	};

	const formatearcadena = (cadena) => {
		if (cadena.startsWith("max.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("min.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("sum.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("avg.")) {
			return cadena.slice(4);
		} else {
			return cadena;
		}
	};

	//atajos de teclado
	useHotkeys("alt+g", guarda, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: show !== 0,
	});
	useHotkeys("esc", cierra, {
		enableOnContentEditable: true,
		enableOnFormTags: true,
		enabled: show !== 0,
	});

	if (!show || !copyConf || !campos) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<CamposIcon className="section-icon" />
							<h3>{loc("Campos")}</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div className="modal-content-scroll">
						<h4>{loc("Seleccionados")}</h4>
						<div className="selected-fields underline">
							{copyConf.campos.map((campo) => (
								<Fragment key={campo}>
									{copyConf.agregaciones.length === 0 ? ( //si no hay agregaciones
										<div
											key={campo}
											className="button tertiary"
											onClick={() =>
												setCopyConf({
													...copyConf,
													campos: copyConf.campos.filter(
														(elem) => elem !== campo
													),
												})
											}>
											{/* {loc(campos[campo].nombre)} */}
											{formatearPrefijo(campo)}
											{campos[formatearcadena(campo)]?.nombre}
											<TrashIcon alt="Borrar campo" className="remove-field" />
										</div>
									) : (
										//si hay agregaciones, se muestran las opciones para cada campo
										<div
											key={campo}
											className="button tertiary"
											onClick={() =>
												setCopyConf({
													...copyConf,
													campos: copyConf.campos.filter(
														(elem) => elem !== campo
													),
												})
											}>
											{/* {loc(campos[campo.split(".")[1]].nombre)} */}
											{formatearPrefijo(campo)}
											{campos[formatearcadena(campo)]?.nombre}
											<TrashIcon alt="Borrar campo" className="remove-field" />
										</div>
									)}
								</Fragment>
							))}
						</div>
						{/* <h4>{loc('Disponibles')}</h4>
							<div className="available-fields">
							{Object.keys(campos)
								.filter((campo) => {
									//si hay agregaciones, solo se muestran los campos que se pueden agregar
									if (copyConf.agregaciones.length === 0) return true
									else return campos[campo].funcAgregado
								})
								.map((campo, i) => (
									<Fragment key={campo}>
										{copyConf.agregaciones.length === 0 ? //si no hay agregaciones
											<div key={campo} className='button tertiary' onClick={() => setCopyConf({ ...copyConf, campos: copyConf.campos.filter((elem) => elem !== campo) })}>
												{loc(campos[campo].nombre)}
												<TrashIcon alt="Borrar campo" className='remove-field' />
											</div>
											: //si hay agregaciones, se muestran las opciones para cada campo
											<div key={campo}>
												{loc(campo)}
											</div>
										}
									</Fragment>
								))}
							</div> */}
						<h4>{loc("Disponibles")}</h4>
						<div className="available-fields">
							{Object.keys(campos)
								.filter((campo) => {
									//si hay agregaciones, solo se muestran los campos que se pueden agregar
									if (copyConf.agregaciones.length === 0) return true;
									else return campos[campo].funcAgregado;
								})
								.map((campo, i) => (
									<Fragment key={campo}>
										{copyConf.agregaciones.length === 0 ? ( //si no hay agregaciones
											<div key={campo}>
												{/* {campos[campo].nombre} */}
												<input
													type="checkbox"
													checked={copyConf.campos.includes(campo)}
													onChange={(e) => {
														if (e.target.checked)
															setCopyConf({
																...copyConf,
																campos: [...copyConf.campos, campo],
															});
														else
															setCopyConf({
																...copyConf,
																campos: copyConf.campos.filter(
																	(elem) => elem !== campo
																),
															});
													}}
													id={"checkbox-0" + i}
												/>
												<label
													className="button tertiary"
													htmlFor={"checkbox-0" + i}>
													{loc(campos[campo].nombre)}
												</label>
											</div>
										) : (
											//si hay agregaciones, se muestran las opciones para cada campo
											aggs.map((agg, j) => (
												<div key={agg["nombre"] + campo}>
													{/* {agg['nombre']} {campos[campo].nombre} */}
													<input
														type="checkbox"
														checked={copyConf.campos.includes(
															agg["funcAgregado"] + "." + campo
														)}
														onChange={(e) => {
															if (e.target.checked)
																setCopyConf({
																	...copyConf,
																	campos: [
																		...copyConf.campos,
																		agg["funcAgregado"] + "." + campo,
																	],
																});
															else
																setCopyConf({
																	...copyConf,
																	campos: copyConf.campos.filter(
																		(elem) =>
																			elem !== agg["funcAgregado"] + "." + campo
																	),
																});
														}}
														id={"checkbox-0" + j}
													/>
													<label
														className="button tertiary"
														htmlFor={"checkbox-0" + j}>
														{loc(agg["nombre"])} {loc(campos[campo].nombre)}
													</label>
												</div>
											))
										)}
									</Fragment>
								))}
						</div>

						<div className="flex-wrapper underline">
							<button
								className="button primary"
								onClick={() =>
									setCopyConf({
										...copyConf,
										campos: Object.keys(campos).filter((campo) => {
											//si hay agregaciones, solo se añaaden los campos que se pueden agregar
											if (copyConf.agregaciones.length === 0) return true;
											else return campos[campo].funcAgregado;
										}),
									})
								}>
								{loc("Seleccionar todos")}
							</button>
							<button
								className="button tertiary"
								onClick={() => setCopyConf({ ...copyConf, campos: [] })}>
								{loc("Eliminar seleccionados")}
								<TrashIcon alt="Borrar campos" />
							</button>
						</div>
					</div>
					<div className="modal-footer">
						<div className="flex-wrapper flex-end">
							<button className="button primary" onClick={guarda}>
								{loc("Aplicar")}
							</button>
							<button className="button tertiary" onClick={cierra}>
								{loc("Cancelar")}
							</button>
						</div>
					</div>
				</div>
			</main>
		);
};
