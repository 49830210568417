import React, { useContext, useEffect, useState, Fragment } from "react";
//Contexto
import ConexContext from "../context/conex/ConexContext";
// import { Link } from 'react-router-dom';

//SVG
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as DataIcon } from "../assets/img/iconos/acae_listing.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";

//imagenes
// import logo from "../assets/img/iconos/acae_group.svg";
// import loadingGif from "../assets/img/loading.gif";
import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";

//modulos
import Paginacion from "./comun/Paginacion";
import { ModalCampos } from "./ModalCampos";
import { ModalAgg } from "./ModalAgg";
import { ModalFiltros } from "./ModalFiltros";
import { ModalPlantillas } from "./ModalPlantillas";
import { ModalExportar } from "./ModalExportar";
import PlantillasMod from "./PlantillasMod";

const Listado = () => {
	const { peticion, loc, Cargando } = useContext(ConexContext); //contexto

	const confDefecto = {
		//conf por defecto
		vista: "asociado",
		campos: [],
		filtros: {
			nexo: "Y",
			filtros: [
				{
					nexo: "Y",
					filtros: [],
				},
			],
		},
		agregaciones: [],
		ini: 0,
		num: 15,
		orden: "",
	};

	//states
	const [vistas, setVistas] = useState(null); //vistas para el select
	const [campos, setCampos] = useState(null); //campos de la vista para los select
	const [conf, setConf] = useState(confDefecto); //conf con los datos de la petición
	const [List, setList] = useState([]); // listado
	const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [Pendiente, setPendiente] = useState(false); // peticion pendiente
	const [showCampos, setShowCampos] = useState(false); // modal campos
	const [showAgg, setShowAgg] = useState(false); // modal agregaciones
	const [showFiltros, setShowFiltros] = useState(false); // modal filtros
	const [showPlantillas, setShowPlantillas] = useState(false); // modal plantillas
	const [showExportar, setshowExportar] = useState(false); // modal Exportar
	const [showPlantillasMod, setshowPlantillasMod] = useState(false); // modal plantillasmod

	// //refs
	// const rVistas = useRef()

	//funciones
	/**
	 * Pide el listado
	 * @param {Object} json JSON con los datos para la petición
	 */
	const getListado = async (json = conf) => {
		if (
			json.vista === "" ||
			(json.campos.length === 0 && json.agregaciones.length === 0)
		)
			return; //no hay vista seleccionada
		const pet = await peticion("/listado/custom", {
			method: "POST",
			json: json,
		});
		if (pet.estado)
			//algún error
			return;
		setList(pet.res.res);
		setNRes(pet.res.nRes);
		setNTot(pet.res.nRes);
		setPendiente(false);
	};

	const filtrarLista = (cadena) => {
		return List.filter((objeto) =>
			Object.values(objeto).some((valor) =>
				valor?.toString().toLowerCase().includes(cadena.toLowerCase())
			)
		);
	};

	/**
	 * Ordena por el campo seleccionado
	 * @param {String} campo Campo por el que se ordena
	 */
	const orden = (campo) => {
		if (List.length === 0) return; //no hay listado (no se ha pedido nada)
		const nuevoConf = {
			...conf,
			orden: conf.orden === campo + " ASC" ? campo + " DESC" : campo + " ASC",
		};
		setConf(nuevoConf);
		getListado(nuevoConf); //pide de nuevo el listado ordenado
	};

	const formatearPrefijo = (cadena) => {
		if (cadena.startsWith("max.")) {
			return "Máximo ";
		} else if (cadena.startsWith("min.")) {
			return "Mínimo ";
		} else if (cadena.startsWith("sum.")) {
			return "Suma de ";
		} else if (cadena.startsWith("avg.")) {
			return "Media de ";
		} else {
			return "";
		}
	};

	const formatearcadena = (cadena) => {
		if (cadena.startsWith("max.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("min.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("sum.")) {
			return cadena.slice(4);
		} else if (cadena.startsWith("avg.")) {
			return cadena.slice(4);
		} else {
			return cadena;
		}
	};

	//effects
	/**
	 * Pide las vistas si no las tiene
	 */
	useEffect(() => {
		const timer = setTimeout(() => {
			// Acción a realizar después de 1 segundo
			//pide las vistas (solo al principio)
			async function vistas_peticion() {
				const pet = await peticion("/listado/permisos_vista", {
					method: "POST",
				});
				if (pet.estado)
					//algún error
					return;
				setVistas(pet.res.vistas);
				//pido la plantilla por defecto si la hay
				const plant = await peticion(`/listado/plantillaPorDefecto`, {
					method: "GET",
				});
				if (plant.estado)
					//algún error
					return;
				if (plant.res.res.length > 0) {
					//hay plantilla por defecto
					const plantilla = JSON.parse(plant.res.res[0].plantilla);
					setConf((prevConf) => ({
						...prevConf,
						campos: plantilla.campos,
						filtros: plantilla.filtros,
						agregaciones: plantilla.agregaciones,
						vista: plant.res.res[0].app,
					}));
					//pido el listado con la plantilla por defecto
					getListado({
						...conf,
						campos: plantilla.campos,
						filtros: plantilla.filtros,
						agregaciones: plantilla.agregaciones,
						vista: plant.res.res[0].app,
					});
				}
			}
			if (vistas === null) vistas_peticion(); //pide las vistas, si no las tiene
		}, 5); // 1000 ms = 1 segundo
		// Limpia el temporizador si el componente se desmonta antes de que termine
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [vistas]);

	/**
	 * Pide los campos de la vista seleccionada, cada vez que se cambia la vista
	 */
	useEffect(() => {
		const timer = setTimeout(() => {
			// Acción a realizar después de 1 segundo
			//pide los campos de la vista seleccionada
			async function campos_peticion() {
				setCampos(null); //resetea los campos
				setList([]); //elimina el listado
				setNRes(0); //resetea el nRes de la paginación
				setNTot(0); //resetea el nTot de la paginación
				if (!conf?.plantilla) {
					setConf((prevConf) => ({
						//resetea el conf, menos la vista
						...prevConf,
						campos: confDefecto.campos,
						filtros: confDefecto.filtros,
						agregaciones: confDefecto.agregaciones,
						ini: confDefecto.ini,
						num: confDefecto.num,
						orden: confDefecto.orden,
					}));
				}
				setPendiente(true); //pone la petición en pendiente

				//si no hay vista seleccionada, no hace nada
				if (conf.vista === "") return;

				const pet = await peticion(
					"/listado/campos_filtrar?vista=" + conf.vista,
					{ method: "POST" }
				);
				if (pet?.estado ?? false)
					//algún error
					return null;
				setCampos(pet.res.campos);
			}
			campos_peticion(); //pide los campos de la vista seleccionada
		}, 5); // 1000 ms = 1 segundo
		// Limpia el temporizador si el componente se desmonta antes de que termine
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [conf.vista]);

	useEffect(() => {
		const timer = setTimeout(() => {
			// Acción a realizar después de 1 segundo
			//pide el listado
			if (conf.num !== null && conf.ini !== null) getListado();
		}, 5); // 1000 ms = 1 segundo
		// Limpia el temporizador si el componente se desmonta antes de que termine
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [conf.num, conf.ini]);

	useEffect(() => {
		setPendiente(true);
	}, [conf.agregaciones, conf.filtros, conf.campos]);

	// useEffect(() => {
	// 	if (conf) {
	// 		console.log(conf);
	// 	}
	// }, [conf])

	return (
		<>
			<div className="content-wrapper main-section" id="listado">
				<header className="backdrop">
					<div className="flex-row-item">
						<DataIcon className="section-icon" alt="Listado" />
						<h1>{loc("Listado")}</h1>
					</div>
					<div className="flex-row-item gap-small">
						{vistas &&
							vistas.map((elem) => (
								<button
									className={`button tertiary ${
										elem.codigo === conf.vista ? "active" : ""
									}`}
									onClick={() => {
										setConf((prevConf) => ({
											...prevConf,
											vista: elem.codigo === 0 ? "" : elem.codigo,
											campos: confDefecto.campos,
											filtros: confDefecto.filtros,
											agregaciones: confDefecto.agregaciones,
											ini: confDefecto.ini,
											num: confDefecto.num,
											orden: confDefecto.orden,
										}));
									}}
									value={elem.codigo}
									key={elem.nombre}>
									{loc(elem.nombre)}
								</button>
							))}
						{/* <select
							ref={rVistas}
							value={conf.vista || ''}
							onChange={() => {
								setConf(prevConf => ({
									...prevConf,
									vista: (rVistas.current.value === 0) ? "" : rVistas.current.value
								}));
							}}>
							<option value={""}>Seleccionar vista</option>
							{vistas &&
								vistas.map((elem) => (
									<option value={elem.codigo} key={elem.nombre}>{elem.nombre}</option>
								))
							}
						</select> */}
					</div>
				</header>

				{Cargando && (!showCampos && !showAgg && !showFiltros && !showPlantillas && !showExportar && !showPlantillasMod) ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							Cargando...
						</span>
					</div>
				) : (
					<div className="content-wrapper">
						<div className="backdrop vista-filtering flex-row-item space-between">
							<div className="flex-row-item gap-small">
								<button
									className={`button tertiary ${
										conf.vista === "" ? "disabled" : "enabled"
									}`}
									onClick={() => {
										if (conf.vista !== "") setShowCampos(true);
									}}>
									{loc("Campos") + " (" + conf.campos.length + ")"}
								</button>
								<button
									className={`button tertiary ${
										conf.vista === "" ? "disabled" : "enabled"
									}`}
									onClick={() => {
										if (conf.vista !== "") setShowFiltros(true);
									}}>
									{loc("Filtros") +
										" (" +
										conf.filtros.filtros.reduce((total, filtro) => {
											return total + filtro.filtros.length;
										}, 0) +
										")"}
								</button>
								<button
									className={`button tertiary ${
										conf.vista === "" ? "disabled" : "enabled"
									}`}
									onClick={() => {
										if (conf.vista !== "") setShowAgg(true);
									}}>
									{loc("Agregaciones") + " (" + conf.agregaciones.length + ")"}
								</button>
							</div>
							<div className="flex-row-item gap-small">
								<button
									className={`button tertiary ${
										conf.vista === "" ? "disabled" : "enabled"
									}`}
									onClick={() => {
										if (conf.vista !== "") setShowPlantillas(true);
									}}>
									{loc("Cargar plantilla")}
								</button>
								<button
									className={`button tertiary ${
										conf.campos.length === 0 &&
										conf.filtros.filtros[0].filtros.length === 0 &&
										conf.agregaciones.length === 0
											? "disabled"
											: "enabled"
									}`}
									onClick={() => {
										if (conf.vista !== "") setshowPlantillasMod(true);
									}}>
									<MasIcon />
									{loc("Guardar como plantilla")}
								</button>
							</div>
						</div>
						
						<div className="backdrop content-wrapper">
							<div className="vista-listado">
								<h2>
									{loc(
										(conf &&
											vistas &&
											vistas.find((elem) => elem.codigo === conf.vista)?.nombre) ||
											""
									)}
								</h2>
								<span className="info-text">
									{conf.vista === ""
										? loc("Selecciona una vista")
										: conf.campos.length < 1 && conf.agregaciones.length < 1
										? loc("Selecciona al menos un campo")
										: ""}
								</span>
								<div>
									<button
										className={`button tertiary ${
											conf.vista === "" ||
											List.length === 0 ||
											(conf.campos.length === 0 && conf.agregaciones.length === 0)
												? "disabled"
												: "enabled"
										}`}
										onClick={() => {
											if (conf.vista !== "") setshowExportar(true);
										}}>
										{loc("Exportar")}
									</button>
									<button
										className={`cta ${
											(conf.campos.length < 1 && conf.agregaciones.length < 1) ||
											conf.vista === ""
												? "disabled"
												: "enabled"
										}`}
										onClick={() => {
											getListado();
										}}>
										{loc("Mostrar resultados")}
									</button>
								</div>
							</div>
							{Pendiente ||
							(conf.campos.length < 1 && conf.agregaciones.length < 1) ? (
								""
							) : (
								<>
									<div className="filtering">
										<Paginacion
											nrPag={List.length}
											NRes={NRes}
											NTot={NTot}
											Ini={conf.ini}
											setListParams={setConf}
											Num={conf.num}
											// Filtro={Conf.filtro}
											Filtro={""}
										/>
										{/* <Filtrado
									setListParams={setListParams}
									Filtro={Conf.filtro}
								/> */}
									</div>
						
									<div className="table-container">
										<table className="listado">
											<thead className="table-headers">
												<tr>
													{conf.agregaciones.length > 0 && (
														<th key={"ntotal"}>Total</th>
													)}
													{campos &&
														conf.agregaciones.map((elem) => (
															<th key={elem}>
																<button onClick={() => orden(elem)}>
																	{campos[elem]?.nombre}
																</button>
																<ul className="listaordena flex-item-row gap-small">
																	{conf.orden === elem + " DESC" && (
																		<li>
																			<img src={desc} alt="ordena descendente" />
																		</li>
																	)}
																	{conf.orden === elem + " ASC" && (
																		<li>
																			<img src={asc} alt="ordena ascendente" />
																		</li>
																	)}
																</ul>
															</th>
														))}
													{campos &&
														conf.campos.map((elem) => (
															<th key={elem}>
																<button onClick={() => orden(elem)}>
																	{formatearPrefijo(elem)}
																	{campos[formatearcadena(elem)]?.nombre}
																</button>
																<ul className="listaordena">
																	{conf.orden === elem + " DESC" && (
																		<li>
																			<img src={desc} alt="ordena descendente" />
																		</li>
																	)}
																	{conf.orden === elem + " ASC" && (
																		<li>
																			<img src={asc} alt="ordena ascendente" />
																		</li>
																	)}
																</ul>
															</th>
														))}
												</tr>
											</thead>
											<tbody className="table-results">
												{filtrarLista(conf?.filtro || "").map((elem, i) => (
													<Fragment key={i}>
														<tr>
															{conf.agregaciones.length > 0 && (
																<td key={"ntotal"}>
																	{elem["ntotal"]?.toString() ?? ""}
																</td>
															)}
															{conf.agregaciones.map((campo) => (
																<td key={campo}>{elem[campo]?.toString() ?? ""}</td>
															))}
															{conf.campos.map((campo) => (
																<td key={campo}>{elem[campo]?.toString() ?? ""}</td>
															))}
														</tr>
													</Fragment>
												))}
											</tbody>
										</table>
									</div>
								</>
							)}
							<ModalCampos
								show={showCampos}
								setShow={setShowCampos}
								conf={conf}
								setConf={setConf}
								campos={campos}
							/>
							<ModalAgg
								show={showAgg}
								setShow={setShowAgg}
								conf={conf}
								setConf={setConf}
								campos={campos}
							/>
							<ModalFiltros
								show={showFiltros}
								setShow={setShowFiltros}
								conf={conf}
								setConf={setConf}
								campos={campos}
							/>
							<ModalPlantillas
								show={showPlantillas}
								setShow={setShowPlantillas}
								conf={conf}
								setConf={setConf}
								campos={campos}
								vistas={vistas}
							/>
							<ModalExportar
								show={showExportar}
								onHide={() => setshowExportar(false)}
								json={conf}
							/>
							<PlantillasMod
								show={showPlantillasMod}
								conf={conf}
								setConf={setConf}
								onHide={() => setshowPlantillasMod(false)}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Listado;
