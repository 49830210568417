import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//SVG
import { ReactComponent as WriteIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";

//imagenes
// import loadingGif from "../assets/img/loading.gif"
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";
//modulos
import DojosMod from './DojosMod'
import Paginacion from './comun/Paginacion';
import MenuToggle from './comun/menuToggle';


const Dojos = ({ setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_asoc,
		perfil_dojo,
		Cargando
	} = useContext(ConexContext)//contexto
	const { idReg, asoc, lang } = useParams();
	//STATES
	const [Detalles, setDetalles] = useState(0)// id del que se debe mostrar detalles
	const [VerMod, setVerMod] = useState(0)// para ver el modal de Ficheros
	const [List, setList] = useState([])// listado
	const [NRes, setNRes] = useState(0)// Nº de registros con filtro
	const [NTot, setNTot] = useState(0)// Nº de registros con filtro
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: 'nombre',
		filtro: '',
		ini: 0,
		nomAso: '',
		idAsoc: 0,
		idReg: 0
	})

	//atajos de teclado
	useHotkeys('alt+n', () => { if (perfil_admin | perfil_adm_asoc) setVerMod(-1) })


	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/dojos/del', {
			method: 'POST', json: {
				id: id
			}
		})
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("El dojo no se puede eliminar"),
				text: loc("El dojo tiene asociados asignados, por lo que no puede ser eliminado"),
				showConfirmButton: true,
				icon: 'error',
			})
			return
		}
		if (pet?.estado ?? true)//algún error 
			return;
		Swal.fire({
			title: loc("Dojo eliminado"),
			showConfirmButton: false,
			icon: 'success',
			timer: 1500
		})
		setListParams({ ...ListParams })
	}

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + ' DESC' })
		else
			setListParams({ ...ListParams, orden: campo })
	}

	//EFFECT
	useEffect(() => {
		//listado en sí de los asociados
		const listar = async () => {

			const pet = await peticion('/dojos/?' +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}&` +
				`idAsoc=${ListParams.idAsoc}`
			)
			if (pet?.estado ?? false)//algún error 
				return null;
			setList(pet.res)
			setNRes(pet.nRes)
			setNTot(pet.nTot)
		}
		//peticiíon de nombre de la asociación
		const getAsoc = async (id) => {
			const pet = await peticion('/asocs/ver?id=' + id)
			if (pet?.estado ?? false)//algún error 
				return;
			setListParams((prev) => ({
				...prev,
				idAsoc: pet?.res?.idAsoc ?? id,
				nomAso: pet?.res?.nombre ?? ''
			}))
		}
		//devuelve la pos de un id
		const getPosicion = async (id) => {
			const pet = await peticion('/dojos/posicion?' +
				`id=${id}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}&` +
				`idAsoc=${ListParams.idAsoc}`)
			if (pet?.estado ?? false)//algún error 
				return;
			if (pet?.res?.pos ?? false) {
				const pos = pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1
				if (pos !== ListParams.ini)
					setListParams((prev) => ({
						...prev,
						ini: pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1,
						idReg: id
					}))
				else setListParams((prev) => ({ ...prev, idReg: id }))

			}
		}
		let id = parseInt(asoc.split('_')[1])//asoc_xxx
		const idAsoc = (isNaN(id) ? 0 : id)//id del dojo de la url en número
		const idRegN = (isNaN(parseInt(idReg)) ? 0 : parseInt(idReg))//id del asoc de la url en número
		// console.log('idRegN',idRegN,ListParams.idReg);
		if (ListParams.idAsoc !== idAsoc)//hay una asoc nueva en la url
			getAsoc(idAsoc);//peticion asoc y recarga de ListParams -> en la siguente pasaremos por listar
		else if (ListParams.idReg !== idRegN)// hay que listar un asociado concreto
			getPosicion(idRegN)
		else {
			listar()
		}
		// eslint-disable-next-line
	}, [ListParams, asoc, idReg])

	// Comprobamos si es admin/adminDojo para mostrar u ocultar los campos
	const mostrarCampos = perfil_admin || perfil_adm_asoc === true || perfil_adm_dojo === true

	return (
		<>
			<div className='content-wrapper main-section' id='dojos'>
				<header className='backdrop space-between'>
					<div className='flex-row-item'>
						<DojoIcon className='section-icon' alt='Dojos' />
						<h1>
							{loc('Dojos')}
						</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />

				</header>
				{/* <div className="col-xs-9">

					<div className="col-xs-12 titularh2">
						<img src={logo} alt='asoc' />
						<h2>{loc('Dojos')}{(ListParams?.idAsoc ?? false) ? ` ${loc('de')}: ${ListParams?.nomAso ?? ''}` : ''}</h2>
					</div>
				</div> */}

				{Cargando && !VerMod ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							Cargando...
						</span>
					</div>
				) : (
					<div className='backdrop content-wrapper'>
						<div className='filtering'>
							<Paginacion
								nrPag={List.length}
								NRes={NRes}
								NTot={NTot}
								Ini={ListParams.ini}
								setListParams={setListParams}
								Num={ListParams.num}
								Filtro={ListParams.filtro}
							/>
						</div>

						<div className='table-container'>
							<table className='table-dojos'>
								<thead className='table-headers'>
									<tr>
										<th style={{ width: '35%' }} >
											<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'nombre' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'nombre DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '20%' }} className='ocultamovil'>
											<button onClick={() => orden('ciudad')}>{loc('Ciudad')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'ciudad' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'ciudad DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '25%' }}>
											<button onClick={() => orden('asoc')}>{loc('Asociacion')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'asoc' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'asoc DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										{mostrarCampos && (
											<>
												<th style={{ width: '10%' }} className='ocultamovil short-column'>
													<button onClick={() => orden('nAsociados')}>{loc('Asociados')}</button>
													<ul className="listaordena">
														{ListParams.orden === 'nAsociados' &&
															<li><img src={asc} alt='ordena ascendente' /></li>
														}
														{ListParams.orden === 'nAsociados DESC' &&
															<li><img src={desc} alt='ordena descendente' /></li>
														}
													</ul>
												</th>
												<th style={{ width: '10%' }} className='table-actions hide-text'>Ac</th>
											</>
										)}
									</tr>
								</thead>
								<tbody className='table-results'>
									{List.map((elem) => (
										<Fragment key={elem.idDojo} >
											<tr className={parseInt(elem.idDojo) === parseInt(idReg) ? 'selected' : ''}>
												<td style={{ width: '35%' }}>
													<div className='fixposition'>
														<p className="fixsangria">{elem.nombre}</p>
													</div>
												</td>
												<td style={{ width: '20%' }} className='ocultamovil'>{elem.ciudad}</td>
												<td style={{ width: '25%' }} className='ajustawdmovil'><p>{elem.asoc}</p>
													<div className='detalles'>
														{Detalles !== elem.idDojo ?
															<button onClick={() => setDetalles(elem.idDojo)}><img src={despliega} alt="despliega" /></button>
															:
															<button onClick={() => setDetalles(0)}><img src={pliega} alt="pliega" /></button>
														}
													</div>
												</td>
												{mostrarCampos && (
													<>
														<td style={{ width: '10%' }} className='ocultamovil table-button short-column'>
															{perfil_admin || (perfil_adm_asoc && (elem.cfAsoc === perfil_asoc)) || (perfil_adm_dojo && (elem.idDojo === perfil_dojo)) ?
																<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }}>
																	<button>
																		<ViewIcon />
																		<span>{elem.numAsoc > 0 ? elem.numAsoc : ''}</span>
																	</button>
																</Link> : null
															}

														</td>
														<td style={{ width: '10%' }} className='ocultamovil table-actions'>
															{perfil_admin || (perfil_adm_asoc && (elem.cfAsoc === perfil_asoc)) || (perfil_adm_dojo && (elem.idDojo === perfil_dojo)) ?
																<ul className="flex-row-item no-gap">
																	<li>
																		<WriteIcon alt='write' onClick={() => setVerMod(elem.idDojo)} />

																	</li>
																	<li>
																		<TrashIcon alt='trash' onClick={() =>
																			Swal.fire({
																				title: loc("¿Seguro que quieres eliminar el dojo?"),
																				showCancelButton: true,
																				confirmButtonText: loc("Sí"),
																				icon: 'question',
																				cancelButtonText: loc('No')
																			}).then((result) => {
																				if (result.isConfirmed) borrar(elem.idDojo)
																			})} />
																	</li>
																</ul> : null
															}
														</td>
													</>
												)}
											</tr>
											{/* <tr className='listaoculta'>
										<td colSpan={6}>
											<ul>
												<li>{elem.ciudad}</li>
												<li>
													<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }} className='d-flex justify-content-end'>
														<button className="btred" style={{ width: '100%' }}>
															<span>
																{loc('Ver asociados')}
															</span>
															<span>{elem.numAsoc > 0 ? `(${elem.numAsoc})` : ''}</span>
														</button>
													</Link>
												</li>
												<li>
													{!perfil_admin && (elem.cfAsoc !== perfil_asoc) ? '' :
														<ul>
															<li><button><span><img src={write} alt='write' onClick={() => setVerMod(elem.idDojo)} /></span></button></li>
															<li><button><span><img src={trash} alt='trash' onClick={() =>
																Swal.fire({
																	title: "¿Seguro que quieres eliminar el dojo?",
																	showCancelButton: true,
																	confirmButtonText: "Sí",
																	icon: 'question',
																	cancelButtonText: 'No'
																}).then((result) => {
																	if (result.isConfirmed) borrar(elem.idDojo)
																})} /></span></button>
															</li>
														</ul>
													}
												</li>
											</ul>
										</td>
									</tr> */}
										</Fragment>

									))

									}
								</tbody>
							</table>
						</div>

						{/* <div className="dojos contresto col-xs-12">

							<div className="col-xs-12">
								<table border="0" className="tabladatos tabladojos" style={{ borderCollapse: "collapse" }}>
									<tbody>
										<tr>
											<td style={{ width: '30%' }} >
												<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
												<ul className="listaordena">
													{ListParams.orden === 'nombre' &&
														<li><img src={asc} alt='ordena ascendente' /></li>
													}
													{ListParams.orden === 'nombre DESC' &&
														<li><img src={desc} alt='ordena descendente' /></li>
													}
												</ul>
											</td>
											<td style={{ width: '20%' }} className='ocultamovil'>
												<button onClick={() => orden('ciudad')}>{loc('Ciudad')}</button>
												<ul className="listaordena">
													{ListParams.orden === 'ciudad' &&
														<li><img src={asc} alt='ordena ascendente' /></li>
													}
													{ListParams.orden === 'ciudad DESC' &&
														<li><img src={desc} alt='ordena descendente' /></li>
													}
												</ul>
											</td>
											<td style={{ width: '25%' }}>
												<button onClick={() => orden('asoc')}>{loc('Asociacion')}</button>
												<ul className="listaordena">
													{ListParams.orden === 'asoc' &&
														<li><img src={asc} alt='ordena ascendente' /></li>
													}
													{ListParams.orden === 'asoc DESC' &&
														<li><img src={desc} alt='ordena descendente' /></li>
													}
												</ul>
											</td>
											{mostrarCampos && (
												<>
													<td style={{ width: '20%' }} className='ocultamovil'>
														<button onClick={() => orden('nAsociados')}>{loc('Ver asociados')}</button>
														<ul className="listaordena">
															{ListParams.orden === 'nAsociados' &&
																<li><img src={asc} alt='ordena ascendente' /></li>
															}
															{ListParams.orden === 'nAsociados DESC' &&
																<li><img src={desc} alt='ordena descendente' /></li>
															}
														</ul>
													</td>
													<td style={{ width: '5%' }}></td>
												</>
											)}
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-xs-12 scroll">
								<table border="0" className="tablaresultados tabladojos" style={{ borderCollapse: "collapse" }}>
									<tbody>

										{List.map((elem) => (
											<Fragment key={elem.idDojo} >
												<tr className={parseInt(elem.idDojo) === parseInt(idReg) ? 'selected' : ''}>
													<td style={{ width: '30%' }}>
														<div className='fixposition'>
															<img src={arrow} alt='arrow' className='arrow' /><p className="fixsangria">{elem.nombre}</p>
														</div>
													</td>
													<td style={{ width: '20%' }} className='ocultamovil'>{elem.ciudad}</td>
													<td style={{ width: '25%' }} colSpan="2" className='ajustawdmovil'><p>{elem.asoc}</p>
														<div className='detalles'>
															{Detalles !== elem.idDojo ?
																<button onClick={() => setDetalles(elem.idDojo)}><img src={despliega} alt="despliega" /></button>
																:
																<button onClick={() => setDetalles(0)}><img src={pliega} alt="pliega" /></button>
															}
														</div>
													</td>
													{mostrarCampos && (
														<>
															<td style={{ width: '20%', textAlign: 'center' }} className='ocultamovil'>
																{perfil_admin || (perfil_adm_asoc && (elem.cfAsoc === perfil_asoc)) || (perfil_adm_dojo && (elem.idDojo === perfil_dojo)) ?
																	<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }} className='d-flex justify-content-end'>
																		<button className="btred" style={{ width: '100%' }}>
																			<span>
																				{loc('Ver asociados')}
																			</span>
																			<span>{elem.numAsoc > 0 ? `(${elem.numAsoc})` : ''}</span>
																		</button>
																	</Link> : null
																}

															</td>
															<td style={{ width: '5%' }} className='ocultamovil'>
																{perfil_admin || (perfil_adm_asoc && (elem.cfAsoc === perfil_asoc)) || (perfil_adm_dojo && (elem.idDojo === perfil_dojo)) ?
																	<ul>
																		<li><button><span><img src={write} alt='write' onClick={() => setVerMod(elem.idDojo)} /></span></button></li>
																		<li><button><span><img src={trash} alt='trash' onClick={() =>
																			Swal.fire({
																				title: "¿Seguro que quieres eliminar el dojo?",
																				showCancelButton: true,
																				confirmButtonText: "Sí",
																				icon: 'question',
																				cancelButtonText: 'No'
																			}).then((result) => {
																				if (result.isConfirmed) borrar(elem.idDojo)
																			})} /></span></button>
																		</li>
																	</ul> : null
																}
															</td>
														</>
													)}
												</tr>
												<tr className='borde_gris'></tr>
												<tr className='listaoculta'>
													<td colSpan={6}>
														<ul>
															<li>{elem.ciudad}</li>
															<li>
																<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }} className='d-flex justify-content-end'>
																	<button className="btred" style={{ width: '100%' }}>
																		<span>
																			{loc('Ver asociados')}
																		</span>
																		<span>{elem.numAsoc > 0 ? `(${elem.numAsoc})` : ''}</span>
																	</button>
																</Link>
															</li>
															<li>
																{!perfil_admin && (elem.cfAsoc !== perfil_asoc) ? '' :
																	<ul>
																		<li><button><span><img src={write} alt='write' onClick={() => setVerMod(elem.idDojo)} /></span></button></li>
																		<li><button><span><img src={trash} alt='trash' onClick={() =>
																			Swal.fire({
																				title: "¿Seguro que quieres eliminar el dojo?",
																				showCancelButton: true,
																				confirmButtonText: "Sí",
																				icon: 'question',
																				cancelButtonText: 'No'
																			}).then((result) => {
																				if (result.isConfirmed) borrar(elem.idDojo)
																			})} /></span></button>
																		</li>
																	</ul>
																}
															</li>
														</ul>
													</td>
												</tr>
											</Fragment>

										))

										}
									</tbody>
								</table>
							</div>
						</div> */}
						{(perfil_admin | perfil_adm_asoc) ?
							<div>
								<button className="cta" onClick={() => setVerMod(-1)}>
									<span>
										{ListParams?.idAsoc ?? false ?
											loc('Añadir nuevo dojo a: ') + (ListParams?.nomAso ?? '')
											:
											loc('Añadir nuevo dojo')
										}
									</span>
									<MasIcon alt='mas' />
								</button>
							</div>
							: ''
						}
						<DojosMod
							show={VerMod}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerMod(0)}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export default Dojos