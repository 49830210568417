import React, { useContext, useEffect, useState } from "react";
import ConexContext from "../context/conex/ConexContext";
import { useParams, useNavigate } from "react-router-dom";

import "../assets/css/acae.css";

//SVG
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as AdminIcon } from "../assets/img/iconos/acae_user.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";

//IMG
// import loadingGif from "../assets/img/loading.gif";
import flor from "../assets/img/flor.svg";
import AvatarSimple from "./comun/AvatarSimple";
import MenuToggle from "./comun/menuToggle";
import InscripcionMod from "./InscripcionMod";

const AsociadosVista = ({ setSidebar, Sidebar }) => {
	const { peticion, loc } = useContext(ConexContext);
	const navigate = useNavigate();
	const { lang, numAcae, idReg } = useParams();

	const [avatar, setAvatar] = useState("default.jpg");
	const [Cargando, setCargando] = useState(false);
	const [nombreCompleto, setNombreCompleto] = useState("");
	const [apellido, setApellido] = useState("");
	const [asociadoData, setAsociadoData] = useState(null); // Estado para almacenar la información del asociado
	const [Asociaciones, setAsociaciones] = useState([]);
	const [Dojos, setDojos] = useState([]);
	const [Cuotas, setCuotas] = useState([]);
	const [Cursos, setCursos] = useState([]); //cursos del asociado
	const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [VerIns, setVerIns] = useState(0); // para ver el modal de Inscribirse
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "titulo DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	const colCuotas = [
		"rgb(22 162 147)",
		"rgb(168 0 59)",
		"rgb(224 147 30)",
		"rgb(48 122 180)",
		"rgb(40 158 105)",
		"rgb(183 53 53)",
		"rgb(214 59 113)",
		"rgb(44 184 211)",
		"rgb(48 158 40)",
		"rgb(199 106 0)",
		"rgb(121 18 54)",
		"rgb(106 122 204)",
		"rgb(22 190 77)",
		"rgb(203 63 63)",
		"rgb(168 0 158)",
		"rgb(65 128 199)",
		"rgb(35 208 113)",
		"rgb(228 24 24)",
		"rgb(188 83 132)",
		"rgb(87 64 196)",
	];

	useEffect(() => {
		const cargarDatos = async () => {
			try {
				setCargando(true);
				const asociado = await peticion(`/asociados/ver?numacae=${numAcae}`);
				if (asociado?.estado === 0) {
					setNombreCompleto(asociado.res.nombre);
					setApellido(asociado.res.apellido);
					setAvatar(asociado.res.avatar);
					setAsociadoData(asociado.res);

					// Cargar asociaciones relacionadas al asociado
					const asocs = await peticion(
						`/asocs/?ini=0&pag=1000&orden=nombre&filtro=`
					);
					if (asocs?.estado === 0) setAsociaciones(asocs.res);

					// Cargar dojos de la asociación del asociado
					const dojos = await peticion(
						`/dojos/?ini=0&pag=1000&orden=nombre&filtro=&idAsoc=${asociado.res.cfAsoc}`
					);
					if (dojos?.estado === 0) setDojos(dojos.res);

					// Cargar cuotas pagadas del asociado
					const cuotas = await peticion(
						`/cuotas/listCuotas?idAsoc=${asociado.res.idAso}`
					);
					if (cuotas?.estado === 0) setCuotas(cuotas.res);
					setCargando(false);
				} else {
					navigate(`/${lang}/inicio`);
				}
			} catch (error) {
				console.error("Error cargando los datos del asociado:", error);
			}
		};
		cargarDatos();
	}, [numAcae, lang, peticion, navigate]);

	// useEffect(() => {
	//     console.log(Asociaciones);
	//     console.log(Dojos);
	// }
	// , [Asociaciones, Dojos]);

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			return;
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setCursos(pet.res);
		setNRes(pet.nRes);
		setNTot(pet.nTot);
	};

	return (
		<>
			<div className="content-wrapper main-section" id="inicio">
				<header className="backdrop space-between">
					<div className="flex-row-item">
						<AdminIcon className="section-icon" alt="Inicio" />
						<h1>{loc("Vista de asociado")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !VerIns ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>Cargando...</span>
					</div>
				) : (
					<div className="content-wrapper">
						<div className="backdrop onboarding">
							<div className="flex-row-item space-between">
								<div className="flex-row-item gap-small">
									<h2>
										{nombreCompleto} {apellido}
									</h2>
									<h2 className="numacae">{numAcae}</h2>
								</div>
								<AvatarSimple avatar={avatar} />
							</div>
							{asociadoData &&
							(asociadoData.esAdmin ||
								asociadoData.esAdminAsoc ||
								asociadoData.esAdminDojo) ? (
								<>
									<div className="management">
										<div className="active_user-rights">
											{asociadoData.esAdmin && <h3>{loc("Admin ACAE")}</h3>}
											{asociadoData.esAdminAsoc && (
												<h3>{loc("Admin Asociación")}</h3>
											)}
											{asociadoData.esAdminDojo && <h3>{loc("Admin Dojo")}</h3>}
										</div>
										<div className="horizontal-divider"></div>
										<ul>
											{
											Asociaciones.length===0 ? (
													<div className="dojo_container"><p className="proximamente">Cargando Asociaciones...</p></div>
												):(
											Asociaciones.map(
												(elem) =>
													elem.idAsoc === asociadoData.cfAsoc && (
														<li
															className="flex-row-item gap-small"
															key={elem.idAsoc}>
															<UsersIcon />
															<div className="dojo_container">
																{elem.nombre} ({elem.zona})
															</div>
														</li>
													)
												)
											)}
										</ul>
										<ul>
											{
											Dojos.length===0 ? (
												<div className="dojo_container"><p className="proximamente">Cargando Dojos...</p></div>
											):(
												Dojos.map(
												(elem) =>
													elem.idDojo === asociadoData.cfDojo && (
														<li
															className="flex-row-item gap-small"
															key={elem.idDojo}>
															<DojoIcon />
															<div className="dojo_container">
																{elem.nombre}
															</div>
														</li>
													)
												)
											)}
										</ul>
									</div>
								</>
							) : (
								<>
									<div className="management">
										<div className="horizontal-divider"></div>
										<ul>
											<li className="flex-row-item gap-small">
												<UsersIcon />
												<div className="dojo_container">
													{asociadoData?.nombreAsoc}
												</div>
											</li>
											<li className="flex-row-item gap-small">
												<DojoIcon />
												<div className="dojo_container">
													{asociadoData?.nombreDojo}
												</div>
											</li>
										</ul>
									</div>
								</>
							)}
						</div>

						<div className="grid">
							<div className="backdrop paid-fees">
								{Cuotas.length > 0 ? (
									<div className="content-wrapper">
										<h2>{loc("Últimas cuotas")}</h2>
										<ul className="flex-row-item gap-small">
											{Cuotas.map((el, index) => (
												<li
													className="flex-row-item gap-small"
													key={index}
													style={{
														background: colCuotas[el % 20],
													}}>
													<span>{el}</span>
													<img src={flor} alt="" />
												</li>
											))}
										</ul>
									</div>
								) : (<div className="dcontent-wrapper"><p className="proximamente">Cargando Cuotas...</p></div>)}
							</div>
							<div className="backdrop grados-overview">
								<div className="content-wrapper">
									<h2>Grados</h2>
									<p className="proximamente">Próximamente disponible</p>
								</div>
							</div>
							<div className="backdrop cursos-overview">
								<div className="content-wrapper">
									<h2>Mis cursos</h2>
									<p className="proximamente">Próximamente disponible</p>
								</div>
							</div>
						</div>
						<div className="backdrop cursos-disponibles">
							<div className="content-wrapper">
								<h2>Próximos cursos</h2>
								<ul className="quick-view-curso grid">
									{Cursos.length > 1 ? (
										Cursos.map((curso) =>
											new Date(curso.fechaIni) > new Date() &&
											curso.cfValAdmi ? (
												<li key={curso.idCurso}>
													<div>
														<h4>{curso.titulo}</h4>
														<p className="date">
															{new Intl.DateTimeFormat("es-ES", {
																day: "numeric",
																month: "long",
															})
																.format(new Date(curso.fechaIni))
																.toUpperCase()}
														</p>
														<div className="flex-row-item gap-small">
															<CursoIcon />
															<p className="location">{curso.ciudad}</p>
														</div>
													</div>
													<button
														className="cta"
														onClick={() => {
															setVerIns(curso.idCurso);
														}}>
														Apúntate
													</button>
												</li>
											) : null
										)
									) : (
										<p className="proximamente">No hay cursos disponibles</p>
									)}
								</ul>
							</div>
						</div>
						<div className="backdrop tribunales-disponibles">
							<div className="content-wrapper">
								<h2>Tribunales</h2>
								<p className="proximamente">Próximamente disponible</p>
							</div>
						</div>

						<InscripcionMod
							show={VerIns}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerIns(0)}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default AsociadosVista;
