import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useHotkeys } from 'react-hotkeys-hook';

import '../assets/css/modal.css';
import validErr from '../assets/js/valida'

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";

const DojosMod = (props) => {
	const {
		peticion,
		perfil_admin,
		perfil_asoc,
		loc,
		Cargando 
	} = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang } = useParams();

	//STATES
	const [ErrNombre, setErrNombre] = useState('')// Error en el nombre
	const [ErrCiudad, setErrCiudad] = useState('')// Error en la ciudad
	const [ErrAsoc, setErrAsoc] = useState('')// Error de la asoc
	const [Asociaciones, setAsociaciones] = useState([])//clase con las asociaciones
	const [IdAsociacion, setIdAsociacion] = useState(0)//asociación del select

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })

	//REFs
	const rId = useRef();
	const rNombre = useRef();
	const rCiudad = useRef();
	const rAsoc = useRef();

	//EFFECT
	useEffect(() => {
		async function ver() {
			const pet = await peticion('/dojos/ver?id=' + props.show)
			if (pet.estado)//algún error 
				return;
			try{
				rId.current.value = pet.res.idDojo
				rNombre.current.value = pet.res.nombre
				rCiudad.current.value = pet.res.ciudad
				setIdAsociacion(pet.res?.cfAsoc ?? 0)
				rNombre.current.focus()
				rNombre.current.select()
			}catch(e){}
		}
		async function verAsocs() {//descargo las Asociaciones
			const pet = await peticion('/asocs/?ini=0&pag=1000&orden=nombre&filtro=')
			if (pet.estado) //algún error
				return;
			setAsociaciones(pet?.res ?? [])
		}
		if (props.show === 0)
			return
		verAsocs()
		if (props.show > 0)
			ver()
		else if (props.show < 0) {//INSERTANDO
			rId.current.value = ''
			rNombre.current.value = ''
			rCiudad.current.value = ''
			let asoc = props.ListParams.idAsoc
			if (!perfil_admin)
				asoc = perfil_asoc
			setIdAsociacion(asoc)
			rNombre.current.focus()

		}
	}, [props.show, peticion, perfil_admin, perfil_asoc, props.ListParams])

	// guardar el registro
	async function guarda() {
		//filtros sin necesidad de peticiones
		let error = false
		//requeridos
		error |= validErr(rNombre.current.value === '', setErrNombre, loc('El nombre es requerido.'))
		error |= validErr(rCiudad.current.value === '', setErrCiudad, loc('La ciudad es requerida.'))
		error |= validErr(rAsoc.current.value === '0', setErrAsoc, loc('La asociación es requerida.'))
		if (!perfil_admin && !error)//es perfil de adm asoc, solo guardar en la asov suya
			error |= validErr(parseInt(rAsoc.current.value) !== perfil_asoc, setErrAsoc, loc('Solo puedes guardar en tu asociación.'))


		if (error)//algún error
			return;
		//filtros con peticiones
		let pet
		//comprobamos que nombre no está repetido
		pet = await peticion(`/dojos/rep_nombre?nombre=${rNombre.current.value}&id=${props.show}&idAsoc=${IdAsociacion}`)
		if (!pet?.estado ?? 1)//estado OK
			if ((pet?.res?.idDojo ?? 1) > -1) {
				setErrNombre(loc('Ya existe un dojo con ese nombre en la asociación.'))
				return;
			}
		if (props.show < 0)//inserción
			pet = await peticion('/dojos/ins', {
				method: 'POST',
				json: {
					nombre: rNombre.current.value,
					ciudad: rCiudad.current.value,
					idAsoc: IdAsociacion
				}
			})
		else if (props.show > 0)//modificación
			pet = await peticion('/dojos/mod', {
				method: 'POST',
				json: {
					id: rId.current.value,
					nombre: rNombre.current.value,
					ciudad: rCiudad.current.value,
					idAsoc: IdAsociacion
				}
			})
		let id = pet?.res?.id ?? rId.current.value
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev) => ({ ...prev }))
		else
			navigate(`/${lang}/dojos/asoc_${props.ListParams.idAsoc}/${id}`)
	}
	//cierra el modal
	function cierra() {
		setErrNombre('')
		setErrCiudad('')
		setErrAsoc('')
		props.onHide()
	}


	// if (!perfil_admin && !perfil_adm_asoc)
	// 	return ("")
	if (props.show === 0)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<DojoIcon className='section-icon dojos' />
							<h3>{props.show > 0 ? loc('Modificar dojo') : loc('Nuevo dojo')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>
					<form className='modal-content-scroll form-container'>
						<input ref={rId} type='hidden' />
						<p>{loc('Nombre')}:</p>
						<input ref={rNombre}
							onChange={() => setErrNombre('')}
							placeholder={loc('Nombre del dojo')}
							maxLength={50} />
						<p className='error'>{ErrNombre}</p>
						<p>{loc('Ciudad')}:</p>
						<input ref={rCiudad}
							onChange={() => setErrCiudad('')}
							placeholder={loc('Ciudad donde está el dojo')}
							maxLength={50} />
						<p className='error'>{ErrCiudad}</p>
						<p>{loc('Asociación')}:</p>
						<select
							ref={rAsoc}
							value={IdAsociacion}
							onChange={() => { setIdAsociacion(rAsoc.current.value) }}>
							<option value={0}>{loc('Seleccionar asociación')}</option>
							{Asociaciones.map(
								(elem) => (
									<option value={elem.idAsoc} key={elem.idAsoc}>{elem.nombre} ({elem.zona})</option>
								))
							}
						</select>
						<p className='error'>{ErrAsoc}</p>
					</form>
					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							<button
								type="button"
								onClick={guarda}
								className="button primary">{loc('Aplicar')}
								<GuardarIcon alt='Aplicar' title='Aplicar' />
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
							<p className='error'></p>
						</div>
					</div>
				</div>
			</main >

		);
}

export default DojosMod;