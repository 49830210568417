import React, { useEffect, useContext, useState } from "react";

import "../assets/css/modal.css";

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as InscribirIcon } from "../assets/img/iconos/acae_sign.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as SearchIcon } from "../assets/img/iconos/acae_zoom.svg";

const InscripcionMod = (props) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_dojo,
		// perfil_asoc,
		perfil_dojo,
	} = useContext(ConexContext);
	const [Curso, setCurso] = useState(null); // cursos seleccionado
	const [AlumnosList, setAlumnosList] = useState([]); // alumnos lista
	const [Filtro, setFiltro] = useState(""); // filtro para busqueda
	// const [ListParams, setListParams] = useState({
	// 	orden: "numAcae",
	// 	anio: "",
	// 	selec: [],
	// 	recarga: false,
	// });

	const meses = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];

	//EFFECT
	useEffect(() => {
		async function ver() {
			const curso = await peticion("/cursos/ver?id=" + props.show);
			setCurso(curso.res);
		}

		if (props.show > 0 && !Curso) ver();
		// else if (props.show < 0) {
		// }
		// eslint-disable-next-line
	}, [props.show]);

	useEffect(() => {
		async function buscar() {
			// Pendiente de desarrollar
			const alumnos = await peticion(
				`/cursos/listAlum?filtro=${Filtro}&cod=${Curso.cfOrganiz}&`
			);

			setAlumnosList(alumnos.res);
		}

		if (props.show === 0 || Curso === null) return;
		buscar();
		// eslint-disable-next-line
	}, [Filtro]);

	//cierra el modal
	function cierra() {
		props.onHide();
	}

	if (props.show === 0) return "";
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className="modal-header">
						<div className="flex-wrapper">
							<InscribirIcon className="section-icon asociados" />
							<h3>{loc("Inscripción")}</h3>
						</div>
						<CloseIcon
							onClick={cierra}
							className="modal_close-icon"
							alt="Cerrar ventana"
							title="(ESC)"
						/>
					</div>
					<div className="modal-content-scroll">
					{Curso && (
						<div className="inscribir-asociado-container underline">
							<div>
								<div className="flex-column gap-small">
									<div className="flex-row-item gap-small">
										<h4>{Curso?.titulo}</h4>
										<div className="flex-row-item gap-small location">
											<CursoIcon className="section-icon" />
											<span>{Curso?.ciudad}</span>
										</div>
									</div>
									<div className="flex-row-item date">
										<span>
											{new Date(Curso?.fechaIni)
												.toLocaleDateString("es-ES", {
													day: "2-digit",
													month: "short",
													year: "numeric",
												})
												.toUpperCase()}
										</span>
										-
										<span>
											{new Date(Curso?.fechaFin)
												.toLocaleDateString("es-ES", {
													day: "2-digit",
													month: "short",
													year: "numeric",
												})
												.toUpperCase()}
										</span>
									</div>
								</div>
							</div>
							<p>{Curso?.obs}</p>
						</div>
					)}

					<div className="inscribir-asociado-container select-sesiones">
						<div className="flex-row-item gap-small">
							<div className="user-avatar">
								<div>
									<img
										src={"/avatars/default.jpg"}
										alt="Avatar"
										className="avatar"
									/>
								</div>
							</div>
							<h4>Nombre Apellidos</h4>
							<h4 className="numacae">NumACAE</h4>
						</div>
						<div className="horizontal-divider" />
						<div className="grid card gap-small underline">
							{Curso &&
								Curso.aSesion &&
								Curso.aSesion.map((elem, i) => (
									<div
										className="flex-column gap-small sesion selected"
										key={i}>
										<div>
											{elem?.fecha?.toString().slice(-2)} de{" "}
											{meses[parseInt(elem?.fecha.split("-")[1], 10) - 1]}{" "}
											{elem?.fecha.split("-")[0]}
										</div>
										<div className="flex-row-item space-between">
											<div className="date">
												{elem?.horaini} - {elem?.horafin}
											</div>
											<div className="checkbox" />
										</div>
									</div>
								))}
							{/* <div className="flex-row-item gap-small sesion selected" >
									<div>Día 1</div>
									-
									<div>Sesión 1</div>
								</div>
								<div className="flex-row-item gap-small sesion selected" >
									<div>Día 1</div>
									-
									<div>Sesión 2</div>
								</div>
								<div className="flex-row-item gap-small sesion" >
									<div>Día 1</div>
									-
									<div>Sesión 1</div>
								</div> */}
						</div>
						<div className="flex-wrapper flex-end inscribir-button">
							<button className="button primary">{loc("Inscribir")}</button>
						</div>
					</div>
					<div className="inscribir-asociado-container search">
						<form>
							<input
								value={Filtro} // valor inicial
								type="search"
								placeholder={loc("Buscar")}
								onChange={(e) => setFiltro(e.target.value)}
							/>
							<SearchIcon alt="" title="" />
						</form>
					</div>
					{perfil_admin || (perfil_adm_dojo && Curso?.dojo === perfil_dojo) ? (
						<div className="inscribir-asociado__table--wrapper">
							<div className="table-container">
								<table className="table-inscripcion-mod">
									<thead className="table-headers">
										<tr>
											<th>
												<button>{loc("Num ACAE")}</button>
											</th>
											<th>
												<button>{loc("Nombre")}</button>
											</th>
											<th>
												<button>{loc("Apellidos")}</button>
											</th>
											<th>
												<button>{loc("Dojo")}</button>
											</th>
											<th>
												<button>{loc("Adulto")}</button>
											</th>
											<th>
												<button>{loc("Última cuota")}</button>
											</th>
										</tr>
									</thead>
									<tbody className="table-results">
										{AlumnosList &&
											AlumnosList.map((elem) => (
												<tr>
													<td className="avatar-row">
														<img
															src={
																"/avatars/" + (elem?.avatar ?? "default.jpg")
															}
															alt=""
														/>{" "}
														{elem?.numacae}
													</td>
													<td>{elem?.nombre}</td>
													<td>{elem?.apellido}</td>
													<td>{elem?.dojo}</td>
													<td>{elem?.adulto}</td>
													<td>{elem?.ultima_cuota}</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					) : null}
					</div>
					<div className="modal-footer">
					<div className="flex-row-item">
						<div className="flex-wrapper flex-end">
							<button className="button tertiary" onClick={cierra}>
								{loc("Cerrar")}
							</button>
						</div>
					</div>
					</div>
				</div>
			</main>
		);
};

export default InscripcionMod;
