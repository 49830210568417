import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';

import '../assets/css/modal.css';

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";

const AsocsMod = (props) => {
	const { peticion, loc, Cargando } = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang } = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })

	//STATES
	const [ErrNombre, setErrNombre] = useState('')// Error en el nombre
	const [ErrSerie, setErrSerie] = useState('')// Error en la serie

	//REFs
	const rId = useRef();
	const rNombre = useRef();
	const rZona = useRef();
	const rSerie = useRef();

	//EFFECT
	useEffect(() => {
		async function ver() {
			const pet = await peticion('/asocs/ver?id=' + props.show)
			if (pet.estado)//algún error 
				return;
			try{
				rId.current.value = pet.res.idAsoc
				rNombre.current.value = pet.res.nombre
				rZona.current.value = pet.res.zona
				rSerie.current.value = pet.res.serie
				rNombre.current.focus()
				rNombre.current.select()
			}catch(e){}
		}
		// console.log('props.show',props.show);
		if (props.show > 0)
			ver()
		else if (props.show < 0) {
			rId.current.value = ''
			rNombre.current.value = ''
			rZona.current.value = ''
			rSerie.current.value = ''
			rNombre.current.focus()
		}
		// eslint-disable-next-line
	}, [props.show])

	// guardar el registro
	async function guarda() {
		//filtros sin necesidad de peticiones
		if (rNombre.current.value === '')
			setErrNombre(loc('El nombre es requerido.'))
		else if (!/^[0-9]{2}[A-Z]$/.test(rSerie.current.value))
			setErrSerie(loc('Formato incorrecto para la serie'))
		else {
			//filtros con peticiones
			let pet
			//comprobamos que nombre no está repetido
			pet = await peticion(`/asocs/rep_nombre?nombre=${rNombre.current.value}&id=${props.show}`)
			if (!pet?.estado ?? 1)//estado OK
				if ((pet?.res?.idAsoc ?? 1) > -1) {
					setErrNombre(loc('Ya existe una asociación con ese nombre.'))
					return;
				}
			pet = await peticion(`/asocs/rep_serie?serie=${rSerie.current.value}&id=${props.show}`)
			if (!pet?.estado ?? 1)//estado OK
				if ((pet?.res?.idAsoc ?? 1) > -1) {
					setErrSerie(loc('Ya existe una asociación con esa serie.'))
					return;
				}

			if (props.show < 0)//inserción
				pet = await peticion('/asocs/ins', {
					method: 'POST', json: {
						nombre: rNombre.current.value,
						zona: rZona.current.value,
						serie: rSerie.current.value,
					}
				})
			else if (props.show > 0)//modificación
				pet = await peticion('/asocs/mod', {
					method: 'POST', json: {
						id: rId.current.value,
						nombre: rNombre.current.value,
						zona: rZona.current.value,
						serie: rSerie.current.value,
					}
				})
			let id = pet?.res?.id ?? rId.current.value
			if (pet.estado)//algún error 
				return;
			cierra()
			if (parseInt(id) === props.ListParams.idReg)
				props.setListParams((prev) => ({ ...prev }))
			else {
				navigate(`/${lang}/asociaciones/${id}`)
			}
		}
	}
	//cierra el modal
	function cierra() {
		setErrNombre('')
		setErrSerie('')
		props.onHide()
	}


	if (props.show === 0)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<UsersIcon className='section-icon cuotas' />
							<h3>{props.show > 0 ? loc('Modificar asociación') : loc('Nueva asociación')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>
					<form className='modal-content-scroll form-container'>
						<input ref={rId} type='hidden' />
						<p>{loc('Nombre')}:</p>
						<input ref={rNombre}
							onChange={() => setErrNombre('')}
							placeholder={loc('Nombre de la asociación')}
							maxLength={100} />
						<p className='error'>{ErrNombre}</p>
						<p>{loc('Zona')}:</p>
						<input ref={rZona} placeholder={loc('Zona de la asociación')} maxLength={100} />
						<p className='error'></p>
						<p>{loc('Número de serie')}:</p>
						<input ref={rSerie}
							onChange={() => setErrSerie('')}
							placeholder={loc('Número de serie de los asociados')}
							maxLength={5} />
						<p className='error'>{ErrSerie}</p>
					</form>
					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							<button
								type="button"
								onClick={guarda}
								className="button primary">
								{loc('Aplicar')}
								<GuardarIcon alt='Aplicar' title='Aplicar' />
								<p className='error'></p>
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
						</div>
					</div>
				</div>
			</main >

		);
}

export default AsocsMod;