import React, { useState, useContext, useEffect, Fragment } from "react";
import {
	// Link,
	useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import { useHotkeys } from "react-hotkeys-hook";

//Contexto
import ConexContext from "../context/conex/ConexContext";

//SVG
import { ReactComponent as WriteIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as ValidarIcon } from "../assets/img/iconos/acae_check.svg";
import { ReactComponent as QuitarIcon } from "../assets/img/iconos/acae_close.svg";
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";
// import { ReactComponent as AbrirIcon } from "../assets/img/iconos/acae_door_open.svg";
// import { ReactComponent as CerrarIcon } from "../assets/img/iconos/acae_door_close.svg";

//imagenes
// import loadingGif from "../assets/img/loading.gif";
import mas from "../assets/img/iconos/acae_add.svg";
import desc from "../assets/img/desc.svg";
import asc from "../assets/img/asc.svg";
//modulos
import CursosMod from "./CursosMod";
import Paginacion from "./comun/Paginacion";
// import CursosInscribir from './CursosInscribir';
import InscripcionMod from "./InscripcionMod";
import MenuToggle from "./comun/menuToggle";

const Cursos = ({ setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_admin,
		perfil_adm_asoc,
		perfil_adm_dojo,
		perfil_dojo,
		Cargando,
	} = useContext(ConexContext); //contexto
	const {
		idReg,
		//lang
	} = useParams();

	//STATES
	// const [Detalles, setDetalles] = useState(0)// id del que se debe mostrar detalles
	const [VerMod, setVerMod] = useState(0); // para ver el modal de Ficheros
	const [VerIns, setVerIns] = useState(0); // para ver el modal de Inscribirse
	const [List, setList] = useState([]); // listado
	const [NRes, setNRes] = useState(0); // Nº de registros con filtro
	const [NTot, setNTot] = useState(0); // Nº de registros con filtro
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: "titulo DESC",
		filtro: "",
		ini: 0,
		nomAso: "",
		idAsoc: 0,
		idReg: 0,
	});

	//atajos de teclado
	useHotkeys("alt+n", () => {
		if (perfil_admin | perfil_adm_asoc | perfil_adm_dojo) setVerMod(-1);
	});

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion("/cursos/delCurso", {
			method: "POST",
			json: {
				idCurso: id,
			},
		});
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("El curso no se puede eliminar."),
				text: loc("Ha habido un error al intentar eliminar el curso"),
				showConfirmButton: true,
				icon: "error",
			});
			return;
		}
		if (pet?.estado ?? true)
			//algún error
			return;
		Swal.fire({
			title: loc("Curso eliminado."),
			showConfirmButton: false,
			icon: "success",
			timer: 1500,
		});
		setListParams({ ...ListParams });
	};

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + " DESC" });
		else setListParams({ ...ListParams, orden: campo });
	};

	//EFFECT
	useEffect(() => {
		const idRegN = isNaN(parseInt(idReg)) ? 0 : parseInt(idReg); // id del curso en número

		if (ListParams.idReg !== idRegN) {
			getPosicion(idRegN);
		} else {
			listar();
		}

		// eslint-disable-next-line
	}, [ListParams, idReg]);

	// Función para listar cursos
	const listar = async () => {
		const pet = await peticion(
			"/cursos/?" +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`
		);
		if (pet?.estado ?? false)
			// algún error
			return null;
		setList(pet.res);
		setNRes(pet.nRes);
		setNTot(pet.nTot);
	};

	// Función para obtener la posición de un curso específico
	const getPosicion = async (id) => {
		// const pet = await peticion('/curso/posicion?' +
		// 	`id=${id}&` +
		// 	`orden=${ListParams.orden}&` +
		// 	`filtro=${ListParams.filtro}&` +
		// 	`idAsoc=${ListParams.idAsoc}`
		// );
		// if (pet?.estado ?? false) // algún error
		// 	return;
		// if (pet?.res?.pos ?? false) {
		// 	const pos = pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1;
		// 	if (pos !== ListParams.ini)
		// 		setListParams((prev) => ({
		// 			...prev,
		// 			ini: pos,
		// 			idReg: id
		// 		}));
		// 	else setListParams((prev) => ({ ...prev, idReg: id }));
		// }
	};

	// Función para alternar la validación de un curso
	async function validarCurso(id) {
		try {
			const pet = await peticion("/cursos/cursoToggleVal", {
				method: "POST",
				json: {
					idCurso: id,
				},
			});

			console.log(pet);

			// Refresca la lista de cursos después de cambiar el estado de validación
			listar();
		} catch (error) {
			console.error("Error al validar el curso:", error);
		}
	}

	// // Función para alternar el estado de un curso
	// async function abrirCurso(id) {
	// 	try {
	// 		const pet = await peticion('/cursos/cursoToggleAbierto', {
	// 			method: 'POST',
	// 			json: {
	// 				idCurso: id
	// 			}
	// 		});

	// 		console.log(pet);

	// 		// Refresca la lista de cursos después de cambiar el estado de validación
	// 		listar();
	// 	} catch (error) {
	// 		console.error("Error al validar el curso:", error);
	// 	}
	// }

	// Comprobamos si es admin/adminDojo para mostrar u ocultar los campos
	// const mostrarCampos = perfil_admin || perfil_adm_asoc === true

	return (
		<>
			<div className="content-wrapper main-section" id="cursos">
				<header className="backdrop space-between">
					<div className="flex-row-item">
						<CursoIcon className="section-icon" alt="Cursos" />
						<h1>{loc("Cursos")}</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />
				</header>

				{Cargando && !VerMod && !VerIns ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							Cargando...
						</span>
					</div>
				) : (
					<>
						<div className="backdrop content-wrapper">
							<div className="filtering">
								<Paginacion
									nrPag={List.length}
									NRes={NRes}
									NTot={NTot}
									Ini={ListParams.ini}
									setListParams={setListParams}
									Num={ListParams.num}
									Filtro={ListParams.filtro}
								/>
							</div>

							<div className="table-container">
								<table className="table-cursos">
									<thead className="table-headers">
										<tr>
											<th style={{ flexGrow: "3" }}>
												<button onClick={() => orden("titulo")}>
													{loc("Título")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "titulo" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "titulo DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th>
												<button onClick={() => orden("fecha")}>
													{loc("Fecha")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "fecha" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "fecha DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="ocultamovil">
												<button onClick={() => orden("ciudad")}>
													{loc("Ciudad")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "ciudad" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "ciudad DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th className="ocultamovil">
												<button onClick={() => orden("estado")}>
													{loc("Estado")}
												</button>
												<ul className="listaordena">
													{ListParams.orden === "estado" && (
														<li>
															<img src={asc} alt="ordena ascendente" />
														</li>
													)}
													{ListParams.orden === "estado DESC" && (
														<li>
															<img src={desc} alt="ordena descendente" />
														</li>
													)}
												</ul>
											</th>
											<th>
												<button>{loc("Inscripción")}</button>
											</th>
											{(perfil_adm_dojo || perfil_admin) && (
												<th className="table-actions hide-text">Ac</th>
											)}
										</tr>
									</thead>
									<tbody className="table-results">
										{List.map((elem) => (
											<Fragment key={elem.idCurso}>
												<tr
													className={
														parseInt(elem.idCurso) === parseInt(idReg)
															? "selected"
															: ""
													}>
													<td style={{ flexGrow: "3" }}>{elem.titulo}</td>
													<td>
														{
															new Intl.DateTimeFormat("es-ES", {
																day: "numeric",
																month: "numeric",
																year: "numeric",
															}).format(new Date(elem.fechaIni))
														}
														{" "}
														-
														{" "}
														{
															new Intl.DateTimeFormat("es-ES", {
																day: "numeric",
																month: "numeric",
																year: "numeric",
															}).format(new Date(elem.fechaIni))
														}
														</td>
													<td className="ocultamovil">{elem.ciudad}</td>
													<td
														className={`ocultamovil estado-wrapper ${
															elem.cfValAdmi ? "aprobado" : ""
														}`}>
														{perfil_admin ? (
															elem.cfValAdmi === true ? (
																<div>
																	<QuitarIcon
																		onClick={() => validarCurso(elem.idCurso)}
																	/>
																</div>
															) : (
																<div>
																	<ValidarIcon
																		onClick={() => validarCurso(elem.idCurso)}
																	/>
																</div>
															)
														) : (
															""
														)}
														<div
															className={`estado-container ${
																elem.estado === "En curso"
																	? "en-curso"
																	: elem.estado === "Terminado"
																	? "terminado"
																	: ""
															}`}>
															{elem.estado}
														</div>
													</td>
													{elem.cfValAdmi && elem.estado !== "Terminado" ? (
														<td className="table-button inscripcion">
															<div
																className="estado-container"
																onClick={() => {
																	setVerIns(elem.idCurso);
																}}>
																Inscribir
															</div>
														</td>
													) : (
														<td className="table-button inscripcion cerrado">
															<div className="estado-container">Cerrado</div>
														</td>
													)}
													{(perfil_adm_dojo || perfil_admin) && (
														<td className="ocultamovil table-actions">
															{(perfil_admin ||
																(perfil_adm_dojo &&
																	perfil_dojo === elem.cfOrganiz)) && (
																<ul className="flex-row-item no-gap">
																	{elem.cfValAdmi === true ? (
																		""
																	) : (
																		<>
																			<li>
																				<WriteIcon
																					alt="write"
																					onClick={() =>
																						setVerMod(elem.idCurso)
																					}
																				/>
																			</li>
																			<li>
																				<TrashIcon
																					alt="trash"
																					onClick={() =>
																						Swal.fire({
																							title: loc(
																								"¿Seguro que quieres eliminar el curso?"
																							),
																							showCancelButton: true,
																							confirmButtonText: loc("Sí"),
																							icon: "question",
																							cancelButtonText: loc("No"),
																						}).then((result) => {
																							if (result.isConfirmed)
																								borrar(elem.idCurso);
																						})
																					}
																				/>
																			</li>
																		</>
																	)}
																</ul>
															)}
														</td>
													)}
												</tr>
												{/** DETALLES CURSO
											Detalles === elem.idDojo &&
												<>
													<tr className={`listaoculta table-actions`}>
														<td colSpan={6}>
															<ul>
																<li>{elem.ciudad}</li>
																<li>
																	<Link to={{ pathname: `/${lang}/asociados/dojo_${elem.idDojo}` }} className='d-flex justify-content-end'>
																		<button className="button primary" style={{ width: '100%' }}>
																			<span>
																				{loc('Ver asociados')}
																			</span>
																			<span>{elem.numAsoc > 0 ? `(${elem.numAsoc})` : ''}</span>
																		</button>
																	</Link>
																</li>
																<li>
																	{!perfil_admin && (elem.cfAsoc !== perfil_asoc) ? '' :
																		<ul>
																			{elem.cfValAdmi === true ? '' :
																				<>
																					<li><WriteIcon alt='write' onClick={() => setVerMod(elem.idCurso)} /></li>
																					<li><TrashIcon alt='trash' onClick={() =>
																						Swal.fire({
																							title: loc("¿Seguro que quieres eliminar el curso?"),
																							showCancelButton: true,
																							confirmButtonText: loc("Sí"),
																							icon: 'question',
																							cancelButtonText: loc('No')
																						}).then((result) => {
																							if (result.isConfirmed) borrar(elem.idCurso)
																							})} />
																					</li>
																				</>
																			}
																		</ul>
																	}
																</li>
															</ul>
														</td>
													</tr>
												</>
											*/}
											</Fragment>
										))}
									</tbody>
								</table>
							</div>
							{perfil_admin | perfil_adm_asoc | perfil_adm_dojo ? (
								<div>
									<button
										className="button primary"
										onClick={() => setVerMod(-1)}>
										{loc("Añadir nuevo curso")}
										<MasIcon src={mas} alt="Más" />
									</button>
								</div>
							) : (
								""
							)}
						</div>

						<CursosMod
							show={VerMod}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerMod(0)}
							listar={() => listar()}
						/>
						{/* <CursosInscribir
						show={VerIns}
						ListParams={ListParams}
						setListParams={setListParams}
						onHide={() => setVerIns(0)}
					/> */}
						<InscripcionMod
							show={VerIns}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerIns(0)}
						/>
					</>
				)}
			</div>
		</>
	);
};

export default Cursos;
