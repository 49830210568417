import React, { Fragment, useContext, useEffect, useState } from 'react'
import ConexContext from '../context/conex/ConexContext';
import { useHotkeys } from 'react-hotkeys-hook';

import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as FiltrosIcon } from "../assets/img/filter.svg";
import { ReactComponent as BuscarIcon } from "../assets/img/iconos/acae_zoom.svg";

export const ModalFiltros = ({ show, setShow, conf, setConf, campos }) => {
	const { loc, peticion } = useContext(ConexContext)//contexto
	const [copyConf, setCopyConf] = useState(null);//copia del conf
	const [condicionSelec, setCondicionSelec] = useState({
		"campo": null, //campo seleccionado
		"opcion": null, //opcion seleccionada (es, no es, mayor, menor, etc)
		"condicion": "", //valor de la condición
		"agrupacion": 0 //agrupación seleccionada
	});
	const [error, setError] = useState(null); //para mostrar errores
	const [buscarCampo, setBuscarCampo] = useState(""); //para buscar campos
	const [opcionesDistinc, setOpcionesDistinc] = useState(null); //opciones para el filtro distinc

	const nombre_opcion = [{
		"=": loc(" es igual a "),
		"!=": loc(" es distinto a "),
		">=": loc(" es mayor o igual que "),
		"<=": loc(" es menor o igual que "),
		">": loc(" es mayor que "),
		"<": loc(" es menor que ")
	}]

	/**
	 * Si se muestra el modal y no hay copia del conf, se crea
	 */
	useEffect(() => {
		if (show && !copyConf)
			setCopyConf({ ...conf })
	}, [show, copyConf, conf])

	/**
	 * Si se cambia la agrupación seleccionada, campo, etc se resetea el error
	 */
	useEffect(() => {
		if (!show) return
		setError(null)
	}, [show, condicionSelec])

	/**
	 * Si se cambia el campo seleccionado y es de tipo distinc, se piden las opciones
	 */
	useEffect(() => {
		async function distinc() {
			const json = {
				"vista": conf.vista,
				"campos": [
				],
				"filtros": {
					"nexo": "Y",
					"filtros": []
				},
				"agregaciones": [condicionSelec.campo],
				"ini": 0,
				"num": 10000,
				"orden": ""
			}
			const pet = await peticion('/listado/custom', { method: 'POST', json: json })
			if (pet.estado) //algún error
				return;
			setOpcionesDistinc(pet.res.res.map((res) => res[condicionSelec.campo]))
		}
		setOpcionesDistinc(null)
		if (condicionSelec.campo !== null && campos[condicionSelec.campo].tfiltro === 'distinc')
			distinc();
		// eslint-disable-next-line
	}, [condicionSelec.campo, campos, conf.vista])

	// useEffect(() => {
	// 	if (!show) return
	// 	console.log('copyConf', copyConf)
	// }, [copyConf])

	// useEffect(() => {
	// 	if(!show) return
	// 	console.log('condicionSelec', condicionSelec)
	// 	console.log('campos', campos[condicionSelec.campo])
	// }, [condicionSelec])

	/**
	 * Guarda el modal
	 */
	const guarda = () => {
		setConf(copyConf)
		cierra()
	}
	/**
	 * Cierra el modal
	 */
	const cierra = () => {
		setCopyConf(null)
		setShow(false)
		setCondicionSelec({
			"campo": null,
			"opcion": null,
			"condicion": "",
			"agrupacion": 0
		})
		setError(null)
		setBuscarCampo("")
		setOpcionesDistinc(null)
	}

	/**
	 * Añade el filtro a la agrupación seleccionada
	 */
	const anadirFiltro = () => {
		// console.log('anadirFiltro', condicionSelec)
		//hacen falta todos los datos
		if (condicionSelec.campo === null || condicionSelec.opcion === null || condicionSelec.condicion === "") return setError(loc('Faltan datos'))
		//si es distinc y no está en las opciones, error
		if (opcionesDistinc !== null){
			const condicion = opcionesDistinc.length > 0 && typeof opcionesDistinc[0] === 'number'
			? Number(condicionSelec.condicion) // Convertir a número si los elementos de opcionesDistinc son numéricos
			: condicionSelec.condicion; // Mantener como cadena si son strings
			if (campos[condicionSelec.campo].tfiltro === 'distinc' && !opcionesDistinc.includes(condicion)) return setError(loc('Valor no válido'))
		}
		// if (campos[condicionSelec.campo].tfiltro === 'distinc' && !opcionesDistinc.includes(condicionSelec.condicion)) return setError(loc('Valor no válido'))
		//si es número o moneda y no es un número, error
		if (['numero', 'moneda'].includes(campos[condicionSelec.campo].tfiltro) && isNaN(condicionSelec.condicion)) return setError(loc('Número no válido'))

		let nuevoConf = { ...copyConf }
		//añado el filtro a la agrupación seleccionada
		nuevoConf.filtros.filtros[condicionSelec.agrupacion].filtros.push({
			"campo": condicionSelec.campo,
			"tipo": campos[condicionSelec.campo].tfiltro,
			"oper": condicionSelec.opcion,
			"valor": condicionSelec.condicion
		})
		setCopyConf(nuevoConf)
		//reset
		setCondicionSelec({
			...condicionSelec,
			"campo": null,
			"opcion": null,
			"condicion": ""
		})
	}

	/**
	 * Añade una nueva agrupación
	 */
	const anadirNuevaAgrupacion = () => {
		let nuevoConf = { ...copyConf }
		//añado una nueva agrupación
		nuevoConf.filtros.filtros.push({
			"nexo": "Y",
			"filtros": []
		})
		setCopyConf(nuevoConf)
		//reset y selecciono la nueva agrupación como activa
		setCondicionSelec({
			"campo": null,
			"opcion": null,
			"condicion": "",
			"agrupacion": copyConf.filtros.filtros.length - 1
		})
	}

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: show !== 0 })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: show !== 0 })

	if (!show || !copyConf || !campos)
		return ("")
	else
		return (
			<main className="modal-wrapper" >
				<div className="modal-container backdrop content-wrapper" >
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<FiltrosIcon className='section-icon' />
							<h3>{loc('Filtros')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>

					<div className='modal-content-scroll'>
						<h4>{loc('Activos')}</h4>
						<div className='active-filter-wrapper'>
							{copyConf.filtros.filtros.map((agrupacion, indexAgrup) => ( //recorro las agrupaciones
								<Fragment key={indexAgrup}>
									<div className={'filter-group-wrapper' + (condicionSelec.agrupacion === indexAgrup ? " active" : "")} onClick={() => { setCondicionSelec({ "campo": null, "opcion": null, "condicion": "", "agrupacion": indexAgrup }) }}>
										<div className='filter-group-header'>
											<h4>
												{loc('Agrupación')} {indexAgrup + 1}
												{/* {indexAgrup === condicionSelec.agrupacion && loc(<div className='active-checker'></div>)} */}
											</h4>
											<TrashIcon
												className={'button icon' + (copyConf.filtros.filtros.length === 1 ? ' disabled' : '')}
												onClick={() => {
													if (copyConf.filtros.filtros.length === 1) return
													let nuevoConf = { ...copyConf }
													//quito la agrupación
													nuevoConf.filtros.filtros = nuevoConf.filtros.filtros.filter((elem, i) => i !== indexAgrup)
													//si no queda ninguna agrupación, añado una nueva vacía
													if (nuevoConf.filtros.filtros.length === 0)
														nuevoConf.filtros.filtros.push({
															"nexo": "Y",
															"filtros": []
														})

													//si la agrupación seleccionada es la que se borra, reseteo
													if (indexAgrup === condicionSelec.agrupacion) {
														setCondicionSelec({
															"campo": null,
															"opcion": null,
															"condicion": "",
															"agrupacion": 0
														})
													} else {
														// cambiar activa
														setCondicionSelec({
															"campo": null,
															"opcion": null,
															"condicion": "",
															"agrupacion": 0
														})
													}
													setCopyConf(nuevoConf)
												}}
											/>
										</div>
										<div className='filter-group-content'>
											<div
												key={indexAgrup}
												onClick={() => {
													setCondicionSelec({
														"campo": null,
														"opcion": null,
														"condicion": "",
														"agrupacion": indexAgrup
													})
												}}
												className={"flex-wrapper" + (condicionSelec.agrupacion === indexAgrup ? " activo" : "")}
											>

												{agrupacion.filtros.map((filtro, indexFilt) => ( //recorro los filtros de la agrupación
													<Fragment key={indexFilt}>
														<div className='filter-single'>
															<span>
																{loc(campos[filtro.campo].nombre)}
															</span>
															<span>
																{loc(nombre_opcion[0][filtro.oper])}
															</span>
															<span>{loc(filtro.valor)}</span>
															<TrashIcon
																className='button icon'
																onClick={() => {
																	let nuevoConf = { ...copyConf }
																	//quito el filtro
																	nuevoConf.filtros.filtros[indexAgrup].filtros = nuevoConf.filtros.filtros[indexAgrup].filtros.filter((elem, i) => i !== indexFilt)
																	setCopyConf(nuevoConf)
																}}
															/>

														</div>

														{/* Muestra el nexo si dentro de la agrupacion hay mas de un filtro y no es el ultimo */}
														{agrupacion.filtros.length > 1 && indexFilt < agrupacion.filtros.length - 1 &&
															<div
																onClick={() => setCopyConf({ //cambio el nexo de la agrupación
																	...copyConf,
																	"filtros": {
																		...copyConf.filtros,
																		"filtros": copyConf.filtros.filtros.map((agrupacion, i) => {
																			if (i === condicionSelec.agrupacion)
																				return {
																					...agrupacion,
																					"nexo": agrupacion.nexo === "Y" ? "O" : "Y"
																				}
																			else
																				return agrupacion
																		})
																	}
																})}
																className='nexus-field'
															>
																<span>{loc(agrupacion.nexo)}</span>
															</div>
														}
													</Fragment>
												))}
											</div>

										</div>
									</div>
									{/* Muestra el nexo entre agrupaciones si hay mas de una y no es la ultima */}
									{copyConf.filtros.filtros.length > 1 && indexAgrup < copyConf.filtros.filtros.length - 1 &&

										<div
											onClick={() => setCopyConf({ //cambio el nexo entre agrupaciones
												...copyConf,
												"filtros": {
													...copyConf.filtros,
													"nexo": copyConf.filtros.nexo === "Y" ? "O" : "Y"
												}
											})}
											className='nexus-group'
										>
											<span>{loc(copyConf.filtros.nexo)}</span>
										</div>

									}
								</Fragment>
							))
							}
						</div>

						<div className="flex-wrapper underline">
							<button className='button primary'
								onClick={anadirNuevaAgrupacion}
							>
								{loc('Nueva agrupación')}
							</button>
							<button
								className='button tertiary'
								onClick={() => {
									//quito todos los filtros
									setCopyConf({
										...conf,
										"filtros": {
											"nexo": "Y",
											"filtros": [{
												"nexo": "Y",
												"filtros": []
											}]
										}
									})
									//reseteo las opciones
									setCondicionSelec({
										"campo": null,
										"opcion": null,
										"condicion": "",
										"agrupacion": 0
									})
								}}
							>
								{loc('Borrar filtros')}
							</button>
						</div>
						<h4>{loc('Campos')}</h4>
						<div className='search'>
							<form 
								onSubmit={(e) => e.preventDefault()}
							>
								<input
									type="text"
									placeholder={loc('Busca un campo')}
									onChange={(e) => {e.preventDefault(); setBuscarCampo(e.target.value)}}
								/>
								<BuscarIcon alt="buscar" className='buscar' />
							</form>
						</div>
						<div className='available-fields'>
							{Object.keys(campos)
								.filter((campo) => { //filtro los campos por el texto introducido
									if (buscarCampo === "") return true
									else return campos[campo].nombre.toLowerCase().includes(buscarCampo.toLowerCase())
								})
								.map((campo) => ( //recorro los campos
									<div
										key={campo}
										onClick={() => setCondicionSelec({ //selecciono el campo
											...condicionSelec,
											"campo": campo,
											"opcion": null,
											"condicion": ""
										})}
										className={"button tertiary" + (condicionSelec.campo === campo ? " active" : "")}
									>
										{loc(campos[campo].nombre)}
									</div>
								))
							}
						</div>
						{/* Se muestra la ventana de opciones para el filtro si hay uno activo */}
						{condicionSelec.campo !== null &&
							<>
								<h4 className='overline'>{loc('Condiciones')}</h4>
								<div className='flex-wrapper filter-conditions'>
									<span className='field-to-filter'>{loc(campos[condicionSelec.campo].nombre)}</span>
									{campos[condicionSelec.campo].tfiltro === 'texto' ?
										<>
											<button className={condicionSelec.opcion === '=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "=" })}>{loc('es igual a')}</button>
											<button className={condicionSelec.opcion === '!=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "!=" })}>{loc('es diferente a')}</button>
											<div className='search'>
												<form 
													onSubmit={(e) => e.preventDefault()}
												>
													<input
														type="text"
														onChange={(e) => {e.preventDefault(); setCondicionSelec({ ...condicionSelec, "condicion": e.target.value })}}
														placeholder={loc('Valor')}
													/>
												</form>
											</div>
										</>
										: campos[condicionSelec.campo].tfiltro === 'distinc' ?
											<>
												<button className={condicionSelec.opcion === '=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "=" })}>{loc('es')}</button>
												<button className={condicionSelec.opcion === '!=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "!=" })}>{loc('no es')}</button>
												<form 
													onSubmit={(e) => e.preventDefault()}
												>
													<select
														onChange={(e) => {e.preventDefault(); setCondicionSelec({
															...condicionSelec,
															"condicion": e.target.value
														})}
														}
													>
														<option value="">{loc('Seleccionar')}</option>
														{opcionesDistinc &&
															opcionesDistinc.map((opcion, index) => (
																<option key={index}>{opcion}</option>
															))
														}
													</select>
												</form>
											</>
											: ['numero', 'moneda'].includes(campos[condicionSelec.campo].tfiltro) &&
											<>
												<button className={condicionSelec.opcion === '>=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": ">=" })}>{loc('es mayor o igual que')}</button>
												<button className={condicionSelec.opcion === '<=' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "<=" })}>{loc('es menor o igual que ')}</button>
												<button className={condicionSelec.opcion === '>' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": ">" })}>{loc('es mayor que')}</button>
												<button className={condicionSelec.opcion === '<' ? ('button secondary active') : ('button secondary')} onClick={() => setCondicionSelec({ ...condicionSelec, "opcion": "<" })}>{loc('es menor que')}</button>
												<input
													type="text"
													onChange={(e) => {
														setCondicionSelec({ ...condicionSelec, "condicion": e.target.value })
													}}
													placeholder={loc('Valor')}
												/>
											</>
									}
									<button
										className='button primary'
										onClick={anadirFiltro}
									>
										{loc('Añadir')}
									</button>
									<p>{error && error}</p>
								</div>
							</>
						}
					</div>

					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							<button
								className="button primary"
								onClick={guarda}
							>
								{loc('Aplicar')}
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
						</div>
					</div>

				</div>
			</main>
		)
}
