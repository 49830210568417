import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useParams, Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useHotkeys } from 'react-hotkeys-hook';

//Contexto
import ConexContext from "../context/conex/ConexContext";

//SVG
import { ReactComponent as WriteIcon } from "../assets/img/iconos/acae_edit_1.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as MasIcon } from "../assets/img/iconos/acae_add.svg";
import { ReactComponent as ViewIcon } from "../assets/img/iconos/acae_view.svg";
import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";

//imagenes
// import loadingGif from "../assets/img/loading.gif"; 
import desc from "../assets/img/desc.svg"
import asc from "../assets/img/asc.svg"
import pliega from "../assets/img/pliega.svg";
import despliega from "../assets/img/despliega.svg";


//modulos
import AsocsMod from './AsocsMod'
import Paginacion from './comun/Paginacion';
import MenuToggle from './comun/menuToggle';

const Asocs = ({ setSidebar, Sidebar }) => {
	const {
		peticion,
		loc,
		perfil_admin,
		// perfil_adm_asoc,
		// perfil_dojo,
		perfil_asoc,
		Cargando
	} = useContext(ConexContext)//contexto
	const { idReg, lang } = useParams();

	//STATES
	const [VerMod, setVerMod] = useState(0)// para ver el modal de Ficheros
	const [List, setList] = useState([])// listado
	const [NRes, setNRes] = useState(0)// Nº de registros con filtro
	const [NTot, setNTot] = useState(0)// Nº de registros con filtro
	const [ListParams, setListParams] = useState({
		num: 15,
		orden: 'nombre',
		filtro: '',
		ini: 0,
		idDojo: 0,
		nomDoj: '',
		cfAsoc: 0,
		idReg: 0
	})

	//atajos de teclado
	useHotkeys('alt+n', () => { if (perfil_admin) setVerMod(-1) })

	//borra un registro concreto
	const borrar = async (id) => {
		const pet = await peticion('/asocs/del', {
			method: 'POST', json: {
				id: id
			}
		})
		if (pet?.estado ?? 0 === 9) {
			Swal.fire({
				title: loc("La asociación no se puede eliminar."),
				text: loc("La asociación tiene dojos asignados, por lo que no puede ser eliminada"),
				showConfirmButton: true,
				icon: 'error',
			})
			return
		}
		if (pet?.estado ?? true)//algún error 
			return;
		Swal.fire({
			title: loc("Asociación eliminada."),
			showConfirmButton: false,
			icon: 'success',
			timer: 1500
		})
		setListParams({ ...ListParams })
	}

	//cambia el orden
	const orden = (campo) => {
		if (ListParams.orden === campo)
			setListParams({ ...ListParams, orden: campo + ' DESC' })
		else
			setListParams({ ...ListParams, orden: campo })
	}

	//EFFECT
	useEffect(() => {
		//listado en sí de los asociados
		const listar = async () => {

			const pet = await peticion('/asocs/?' +
				`ini=${ListParams.ini}&` +
				`pag=${ListParams.num}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}&`
			)
			if (pet?.estado ?? false)//algún error 
				return null;
			setList(pet.res)
			setNRes(pet.nRes)
			setNTot(pet.nTot)
		}
		//devuelve la pos de un id
		const getPosicion = async (id) => {
			const pet = await peticion('/asocs/posicion?' +
				`id=${id}&` +
				`orden=${ListParams.orden}&` +
				`filtro=${ListParams.filtro}`)
			if (pet?.estado ?? false)//algún error 
				return;
			if (pet?.res?.pos ?? false) {
				const pos = pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1
				if (pos !== ListParams.ini)
					setListParams((prev) => ({
						...prev,
						ini: pet.res.pos - (pet.res.pos - 1) % ListParams.num - 1,
						idReg: id
					}))
				else setListParams((prev) => ({ ...prev, idReg: id }))

			}
		}
		const idRegN = (isNaN(parseInt(idReg)) ? 0 : parseInt(idReg)) //id del asoc de la url en número
		// console.log('idRegN',idRegN,ListParams.idReg);
		if (ListParams.idReg !== idRegN)// hay que listar un asociado concreto
			getPosicion(idRegN)
		else {
			listar()
		}
		// eslint-disable-next-line
	}, [ListParams, idReg])

	return (
		<>
			<div className='content-wrapper main-section' id='asociaciones'>
				<header className='backdrop space-between'>
					<div className='flex-row-item'>
						<UsersIcon className='section-icon' alt='Asociaciones' />
						<h1>
							{loc('Asociaciones')}
						</h1>
					</div>
					<MenuToggle Sidebar={Sidebar} setSidebar={setSidebar} />

				</header>
				{Cargando && !VerMod ? (
					<div className="backdrop precarga">
						<div className="loader-wrapper">
							<div className="circle-wrapper">
								<div className="circle-1" />
								<div className="circle-2" />
								<div className="circle-3" />
								<div className="circle-4" />
								<div className="circle-5" />
							</div>
							<AcaeIcon />
						</div>
						<span>
							Cargando...
						</span>
					</div>
				) : (
					<div className='backdrop content-wrapper'>
						<div className='filtering'>
							<Paginacion
								nrPag={List.length}
								NRes={NRes}
								NTot={NTot}
								Ini={ListParams.ini}
								setListParams={setListParams}
								Num={ListParams.num}
								Filtro={ListParams.filtro}
							/>
						</div>
						<div className='table-container'>
							<table className='table-asocs'>
								<thead className='table-headers'>
									<tr>
										<th style={{ width: '50%' }}>
											<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'nombre' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'nombre DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '20%' }} className='ocultamovil'>
											<button onClick={() => orden('zona')}>{loc('Zona')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'zona' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'zona DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '10%' }} className='ajustawdmovil'>
											<button onClick={() => orden('serie')}>{loc('Serie')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'serie' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'serie DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '10%' }} className='ocultamovil short-column'>
											<button onClick={() => orden('nDoj')}>{loc('Dojos')}</button>
											<ul className="listaordena">
												{ListParams.orden === 'nDoj' &&
													<li><img src={asc} alt='ordena ascendente' /></li>
												}
												{ListParams.orden === 'nDoj DESC' &&
													<li><img src={desc} alt='ordena descendente' /></li>
												}
											</ul>
										</th>
										<th style={{ width: '10%' }} className='ocultamovil table-actions hide-text'>Ac</th>
									</tr>
								</thead>
								<tbody className='table-results'>
									{List.map((elem) => (
										<Fragment key={elem.idAsoc}>
											<tr className={parseInt(elem.idAsoc) === parseInt(idReg) ? 'selected' : ''}>
												<td style={{ width: '50%' }}>
													<div className='fixposition'>
														<p className="fixsangria">{elem.nombre}</p>
													</div>
												</td>
												<td style={{ width: '20%' }} className='ocultamovil'>{elem.zona}</td>
												<td style={{ width: '10%' }} className='ajustawdmovil'><p>{elem.serie}</p>
													<div className='detalles'>
														<button><img src={despliega} alt="despliega" /></button>
														<button><img src={pliega} alt="pliega" /></button>
													</div>
												</td>
												<td style={{ width: '10%' }} className='ocultamovil table-button short-column'>
													<Link to={{ pathname: `/${lang}/dojos/asoc_${elem.idAsoc}` }}>
														<button>
															<ViewIcon />
															<span> {elem.numDoj > 0 ? elem.numDoj : ''}</span>
														</button>
													</Link>
												</td>
												{(perfil_admin | elem.idAsoc === perfil_asoc) ?
													<td style={{ width: '10%' }} className='ocultamovil table-actions'>
														<ul className="flex-row-item no-gap">
															<li><WriteIcon alt='write' onClick={() => setVerMod(elem.idAsoc)} /></li>
															<li><TrashIcon alt='trash' onClick={() => {
																Swal.fire({
																	title: loc("¿Seguro que quieres eliminar la asociación?"),
																	showCancelButton: true,
																	confirmButtonText: loc("Sí"),
																	icon: 'question',
																	cancelButtonText: loc('No')
																}).then((result) => {
																	if (result.isConfirmed) borrar(elem.idAsoc)
																});
															}} />
															</li>
														</ul>
													</td>
													: <td style={{ width: '10%' }} className='ocultamovil table-actions'></td>}
											</tr>
										</Fragment>
									))
									}
								</tbody>
							</table>
						</div>
						{perfil_admin && (
							<div>
								<button className="cta" onClick={() => setVerMod(-1)}>
									<span>
										{loc('Añadir nueva asociación')}
									</span>
									<MasIcon alt='mas' />
								</button>
							</div>
						)}
						<AsocsMod
							show={VerMod}
							ListParams={ListParams}
							setListParams={setListParams}
							onHide={() => setVerMod(0)}
						/>
					</div>
				)}
			</div>
		</>
	)
}

export default Asocs