import React, { useContext, useRef, useEffect } from 'react'

//Contexto
import ConexContext from "../../context/conex/ConexContext";
//SVG
import { ReactComponent as SearchIcon } from "../../assets/img/iconos/acae_zoom.svg";


const Filtrado = ({ setListParams, Filtro }) => {
	const { loc } = useContext(ConexContext)//contexto
	const filtro = useRef()
	useEffect(() => {
		filtro.current.value = Filtro
	}, [Filtro])

	return (
		<>
			<div className="search">
				<form onSubmit={(event) => {
					event.preventDefault(); setListParams((prev) => ({
						...prev,
						filtro: filtro.current.value,
						ini: 0
					}))
				}}>
					<input ref={filtro} type="search" placeholder={loc("Buscar")} defaultValue={Filtro}
						// onChange={() => {setListParams((prev)=>({...prev,filtro:filtro.current.value}))}}
						onBlur={() => {
							setListParams((prev) => ({ ...prev, filtro: filtro.current.value }));
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								setListParams((prev) => ({ ...prev, filtro: filtro.current.value }));
							}
						}}
						></input>
					<SearchIcon alt='' title='' />
				</form>
			</div>
		</>
	)
}

export default Filtrado