import React, { useContext, useEffect, useState, useRef } from 'react'
import {
	Outlet,
	useParams,
	useNavigate,
	NavLink
} from "react-router-dom";

//SVG
import { ReactComponent as UsersIcon } from "../assets/img/iconos/acae_group.svg";
import { ReactComponent as DojoIcon } from "../assets/img/iconos/acae_dojo.svg";
import { ReactComponent as DataIcon } from "../assets/img/iconos/acae_listing.svg";
import { ReactComponent as InicioIcon } from "../assets/img/iconos/acae_home.svg";
import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as CuotaIcon } from "../assets/img/iconos/acae_fees.svg";
import { ReactComponent as AsociadoIcon } from "../assets/img/iconos/acae_kimono.svg";
import { ReactComponent as CloseIcon } from "../assets/img/iconos/acae_close.svg";
// import { ReactComponent as AcaeIcon } from "../assets/img/iconos/acae_flower_fill.svg";

//imagenes
import logo from "../assets/img/logo.jpg"
// import loading from "../assets/img/loading.gif";

import espanol from '../assets/img/espanol.png';
import ingles from '../assets/img/uk.png';
import frances from '../assets/img/frances.png';

import Avatar from './comun/Avatar';

import clickOut from '../assets/js/clickOut'
//Contexto
import ConexContext from "../context/conex/ConexContext";
// import { Inicio } from './Inicio'; 


const Layout = ({ WCuotas, setTimer, Sidebar, setSidebar }) => {
	const navigate = useNavigate()
	const { lang } = useParams();
	const selLang = useRef();
	const [OpLang, setOpLang] = useState(false);
	const {
		setLocale,
		locale,
		loc,
		perfil_admin,
		perfil_adm_dojo,
		perfil_adm_asoc,
		perfil_dojo,
		// Cargando,
	} = useContext(ConexContext)//contexto

	// FUNCIONES
	// cierra el selec de lenguaje
	clickOut(selLang, () => {
		setOpLang(false)
	});

	// Abrir y cerrar barra lateral
	// const toggleSidebar = () => {
	// 	document.getElementById('sidebar').classList.toggle('active');
	// 	document.getElementById('content').classList.toggle('active');
	// 	document.getElementById('sidebaricon').classList.toggle('cambiaicono');
	// }

	useEffect(() => {
		setLocale(lang);
	}, [lang, setLocale])

	useEffect(() => {
		setTimer(0)
	}, [setTimer]);

	// Comprobamos si es cualquier tipo de admin para mostrar u ocultar los campos
	const mostrarCampos = perfil_admin || perfil_adm_asoc || perfil_adm_dojo === true

	// Detectar dispositivos móviles
	const isMobile = window.innerWidth <= 768;

	if (!locale)
		return (null)
	else
		return (
			<div id="wrapper">
				<div className="dashboard">
					<nav className={`backdrop ${Sidebar ? "active-sidebar" : ""}`} id='sidebar'>
						<CloseIcon onClick={()=>setSidebar(!Sidebar)} className='menu-icon close mobile' alt='Cerrar menu' />
						<nav className="nav">
							<img src={logo} className="logo desktop" alt='logo' />
							<ul className='nav-menu'>
								<li className='active'>
									<NavLink to={`/${lang}/inicio`} onClick={()=>setSidebar(false)}>
										<button>
											<InicioIcon />
											{loc('Inicio')}
										</button>
									</NavLink>
								</li>
								<li>
									<NavLink to={`/${lang}/asociaciones`} onClick={()=>setSidebar(false)}>
										<button>
											<UsersIcon />
											{loc('Asociaciones')}
										</button>
									</NavLink>
								</li>
								<li>
									<NavLink to={`/${lang}/dojos/asoc_`} onClick={()=>setSidebar(false)}>
										<button>
											<DojoIcon />
											{loc('Dojos')}
										</button></NavLink>
								</li>
								<li>
									{!perfil_admin & !perfil_adm_asoc ?
										<NavLink to={`/${lang}/asociados/dojo_${perfil_dojo}`} onClick={()=>setSidebar(false)}>
											<button>
												<AsociadoIcon />
												{loc('Asociados')}
											</button>
										</NavLink>
										:
										<NavLink to={`/${lang}/asociados/dojo_`} onClick={()=>setSidebar(false)}>
											<button>
												<AsociadoIcon />
												{loc('Asociados')}
											</button>
										</NavLink>
									}

								</li>
								{mostrarCampos && (
									<li>
										<NavLink to={`/${lang}/cuotas`} onClick={()=>setSidebar(false)}>
											<button>
												<CuotaIcon/>
												{loc('Cuotas')}
												{WCuotas !== 0 ?
													<div className='notification'>{WCuotas}</div> : null
												}
											</button>
										</NavLink>
									</li>
								)}

								<li>
									<NavLink to={`/${lang}/cursos`} onClick={()=>setSidebar(false)}>
										<button>
											<CursoIcon />
											{loc('Cursos')}
										</button></NavLink>
								</li>

								{!isMobile && 
								<li>
									<NavLink to={`/${lang}/listado`} onClick={()=>setSidebar(false)}>
										<button>
											<DataIcon />
											{loc('Listado')}
										</button></NavLink>
								</li>
								}
							</ul>
						</nav>

						{/* select idiomas escritorio */}

						<div className={`selectidiomas loginidiomas ${OpLang ? 'abierto' : 'cerrado'}`} onClick={() => setOpLang(!OpLang)} ref={selLang} id="button">
							<h3><span>{lang.toUpperCase()}</span>{
								lang === 'es' ? <img src={espanol} alt="" />
									: lang === 'en' ? <img src={ingles} alt="" />
										: lang === 'fr' ? <img src={frances} alt="" />
											: null
							}
							</h3>
							<input id="toggle" type="checkbox" />
							<div id="two">
								<ul>
									<li className={lang === 'es' ? 'selec' : ''}><button onClick={() => navigate('/es')}><span>ES</span><img src={espanol} alt="" /></button></li>
									<li className={lang === 'en' ? 'selec' : ''}><button onClick={() => navigate('/en')}><span>EN</span><img src={ingles} alt="" /></button></li>
									<li className={lang === 'fr' ? 'selec' : ''}><button onClick={() => navigate('/fr')}><span>FR</span><img src={frances} alt="" /></button></li>
								</ul>
							</div>
						</div>
						<Avatar />
					</nav>
					<div className={`content ${Sidebar ? 'mobile-hidden' : ''}`}>
						<div className='content-scroll'>
							<Outlet />
						</div>
					</div>
				</div>
			</div>

		)
}

export default Layout