import React, { useContext, useEffect, useState } from 'react'
import ConexContext from '../context/conex/ConexContext';
import { useHotkeys } from 'react-hotkeys-hook';

import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";
import { ReactComponent as TrashIcon } from "../assets/img/iconos/acae_trash.svg";
import { ReactComponent as CamposIcon } from "../assets/img/layout.svg";

// import close2 from "../assets/img/close2.svg"
// import trash from "../assets/img/iconos/acae_trash.svg"
// import trashwhite from "../assets/img/trash_white.svg"

export const ModalAgg = ({ show, setShow, conf, setConf, campos }) => {
	const { loc } = useContext(ConexContext)//contexto
	const [copyConf, setCopyConf] = useState(null);//copia del conf para poder modificarlo sin afectar al original

	/**
	 * Si se muestra el modal y no hay copia del conf, se crea
	 */
	useEffect(() => {
		if (show && !copyConf)
			setCopyConf({ ...conf, campos: [] })
	}, [show, copyConf, conf])

	// useEffect(() => {
	// 	console.log('copyConf', copyConf)
	// }, [copyConf])

	/**
	 * Guarda el modal
	 */
	const guarda = () => {
		setConf(copyConf)
		cierra()
	}
	/**
	 * Cierra el modal
	 */
	const cierra = () => {
		setCopyConf(null)
		setShow(false)
	}
	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: show !== 0 })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: show !== 0 })

	if (!show || !copyConf || !campos)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<CamposIcon className='section-icon' />
							<h3>{loc('Agregaciones')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>
					<div className='modal-content-scroll'>
						<h4>{loc('Seleccionados')}</h4>
						<div className="selected-aggs underline">
						
							{copyConf.agregaciones.map((campo) => (
								<div key={campo}>
									<div key={campo} className='button tertiary' onClick={() => setCopyConf({ ...copyConf, agregaciones: copyConf.agregaciones.filter((elem) => elem !== campo) })}>
										{loc(campos[campo].nombre)}
										<TrashIcon alt="Borrar campo" className='remove-field' />
									</div>
						
								</div>
							))}
						</div>
						<h4>{loc('Disponibles')}</h4>
						<div className="available-aggs">
							{Object.keys(campos).filter((campo) => campos[campo].agregaciones).map((campo, i) => (
								<div key={campo} >
									{/* {campos[campo].nombre} */}
									{/* <input
										type="checkbox"
										checked={copyConf.agregaciones.includes(campo)}
										onChange={(e) => {
											if (e.target.checked)
												setCopyConf({ ...copyConf, agregaciones: [...copyConf.agregaciones, campo] })
											else
												setCopyConf({ ...copyConf, agregaciones: copyConf.agregaciones.filter((elem) => elem !== campo) })
										}}
									/> */}
									<label
										className='button tertiary'
										htmlFor={'checkbox-0' + i}
										onClick={() => {
											if (!copyConf.agregaciones.includes(campo))
												setCopyConf({ ...copyConf, agregaciones: [...copyConf.agregaciones, campo] })
											else
												setCopyConf({ ...copyConf, agregaciones: copyConf.agregaciones.filter((elem) => elem !== campo) })
										}}>
										{loc(campos[campo].nombre)}
									</label>
								</div>
							))}
						</div>
						
						<div className="flex-wrapper underline">
							<button
								className="button primary"
								onClick={() => setCopyConf({ ...copyConf, agregaciones: Object.keys(campos).filter((campo) => campos[campo].agregaciones), campos: [] })}
							>
								{loc('Seleccionar todos')}
							</button>
							<button
								className="button tertiary"
								onClick={() => setCopyConf({ ...copyConf, agregaciones: [] })}
							>
								{loc('Eliminar seleccionados')}
								<TrashIcon alt="borrar" />
							</button>
						</div>
					</div>


					<div className='modal-footer'>
						<div className="flex-wrapper flex-end">
							<button
								className="button primary"
								onClick={guarda}
							>
								{loc('Aplicar')}
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
						</div>
					</div>
				</div>
			</main >

			// 	<main className="custom-modal modalasociados" >
			// 	<div className="modal-main" >
			// 		<div className="container_modal col-lg-12 col-md-12 ">
			// 			<div className="nuevazona col-xs-12">
			// 				<h4 className="col-xs-12">{loc('Selección de campos')}</h4>
			// 				<div className="col-xs-12 d-flex ">
			// 					<button type="button" onClick={cierra} className="btn cierramodal" ><span><img src={close2} alt='cerrar' title='(ESC)' /></span></button>
			// 				</div>
			// 				<div className="col-xs-12 continputs">
			// 					<h3>{loc('Campos seleccionados')}</h3>
			// 					<div className="col-xs-12 ordenalista">

			// 						{copyConf.agregaciones.map((campo) => (
			// 							<div key={campo}>
			// 								{campos[campo].nombre}
			// 								<img
			// 									// src={close} 
			// 									className='borrarcampoimg' src={trash} alt="borrar"
			// 									onClick={() => setCopyConf({ ...copyConf, agregaciones: copyConf.agregaciones.filter((elem) => elem !== campo) })}
			// 								/>

			// 							</div>
			// 						))}
			// 					</div>
			// 					<hr className='col-xs-12'/>
			// 					<div className="col-xs-12">
			// 						<h3>{loc('Campos')}</h3>
			// 						<div className='ordenalista3'>
			// 						{Object.keys(campos).filter((campo) => campos[campo].agregaciones).map((campo) => (
			// 							<div key={campo} >
			// 								{campos[campo].nombre}
			// 								<input
			// 									type="checkbox"
			// 									checked={copyConf.agregaciones.includes(campo)}
			// 									onChange={(e) => {
			// 										if (e.target.checked)
			// 											setCopyConf({ ...copyConf, agregaciones: [...copyConf.agregaciones, campo] })
			// 										else
			// 											setCopyConf({ ...copyConf, agregaciones: copyConf.agregaciones.filter((elem) => elem !== campo) })
			// 									}}
			// 								/>
			// 							</div>
			// 						))}
			// 						</div>

			// 						<div className="col-xs-12 ordenalista pd15">
			// 							<button
			// 								className="btn grey2"
			// 								onClick={() => setCopyConf({ ...copyConf, agregaciones: Object.keys(campos).filter((campo) => campos[campo].agregaciones), campos: [] })}
			// 							>
			// 								{loc('Seleccionar todos')}
			// 							</button>
			// 							<button
			// 								className="btn grey2"
			// 								onClick={() => setCopyConf({ ...copyConf, agregaciones: [] })}
			// 							>
			// 								{loc('Eliminar todos')}
			// 								<img src={trashwhite} alt="borrar"/>
			// 							</button>
			// 						</div>

			// 					</div>
			// 				</div>
			// 				<hr className='col-xs-12 '/>
			// 				<div className="col-xs-12 contbutton">
			// 					<button
			// 						className="btn"
			// 						onClick={() => {
			// 							setConf(copyConf)
			// 							cierra()
			// 						}}
			// 					>
			// 						{loc('Aplicar')}
			// 					</button>
			// 					<button
			// 						className="btn"
			// 						onClick={cierra}
			// 					>
			// 						{loc('Cancelar')}
			// 					</button>
			// 				</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </main>
		)
}
