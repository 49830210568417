import React, { useState, useRef, useContext, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useHotkeys } from 'react-hotkeys-hook';


import '../assets/css/modal.css';

// import moment from 'moment';

//Contexto
import ConexContext from "../context/conex/ConexContext";

import { ReactComponent as CursoIcon } from "../assets/img/iconos/acae_course.svg";
import { ReactComponent as GuardarIcon } from "../assets/img/iconos/acae_save.svg";
import { ReactComponent as CloseIcon } from "../assets/img/close2.svg";

// const avatars = require.context('../../public/avatars/', false, /\.jpg$/)

const CursosMod = (props) => {
	const { peticion, loc, Cargando } = useContext(ConexContext)
	const navigate = useNavigate();
	const { lang } = useParams();

	//atajos de teclado
	useHotkeys('alt+g', guarda, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })
	useHotkeys('esc', cierra, { enableOnContentEditable: true, enableOnFormTags: true, enabled: props.show !== 0 && !Cargando })

	//STATES
	// const [Anio, setAnio] = useState('')// selec de años
	// const [NumAd, setNumAd] = useState(0)// cuotas de adultos
	// const [NumNi, setNumNi] = useState(0)// cuotas de niños
	// const [ListParams, setListParams] = useState({
	// 	orden: 'numAcae',
	// 	selec: [],
	// 	recarga: false
	// })
	// const [List, setList] = useState([])// listo alumnos
	const [Lectura, setLectura] = useState(false) //está en modo lectura

	// const [BProf, setBProf] = useState(false) //input de busqueda de transf
	// const BProf = false;

	const [Formu, setFormu] = useState({}) //todos los campos del formulario
	const [FormuCopy, setFormuCopy] = useState({}) //todos los campos del formulario Copia

	// const [ErrList, setErrList] = useState('')// Error en el listado

	// const [numSesiones, setNumSesiones] = useState(0); // Número de sesiones

	//REFs
	// const idTimer = useRef(null);
	const rTitulo = useRef();


	//devuelve el img del avatar, la por defecto si no existe 
	// const imgAvatar = (id) => {
	// 	return (avatars.keys().includes(`./id_${id}.jpg`) ? avatars(`./id_${id}.jpg`) : avatars(`./avatar.jpg`))
	// }
	//quita o pone un elemento del Select 
	// const toggleSelec = (val) => {
	// 	if (Lectura) return
	// 	const a = [...ListParams.selec]
	// 	let p = [...Formu.aProfs]
	// 	var index = a.indexOf(val);
	// 	if (index === -1) {
	// 		a.push(val);
	// 		p.push(List.filter((el) => el.idAso === val)[0]);

	// 	}
	// 	else {
	// 		a.splice(index, 1);
	// 		// p.slice(1,1)
	// 		p.splice(p.indexOf(p.filter((el) => el.idAso === val)[0]), 1)

	// 	}
	// 	let profs = p.map(el => { return (`${el.nombre} ${el.apellido}`) }).join(', ')
	// 	console.log('profs', profs);
	// 	setListParams((prev) => ({ ...prev, selec: a }))
	// 	setFormu((prev) => ({ ...prev, aProfs: p, profs: profs }))
	// }
	
	//EFFECT
	// useEffect(() => {
	// 	console.log(Formu)
	// }, [Formu]);
	//busqueda de profesor
	// useEffect(() => {
	// 	async function list() {
	// 		const pet = await peticion(`/cursos/listAsoc?filtro=${BProf}&orden=${ListParams.orden}&prof`)
	// 		if (pet?.estado ?? true)//algún error 
	// 			return;
	// 		// setList(pet?.res ?? [])
	// 		setListParams((prev) => ({
	// 			...prev,
	// 			recarga: false
	// 		}))
	// 	}
	// 	if (ListParams.recarga)
	// 		list()
	// 	// eslint-disable-next-line
	// }, [ListParams, peticion]);
	// effect de carga de datos inicial
	// useEffect(() => {
	// 	if (BProf.length > 2) {// si es de 3 o más, y pasan unos segundos, llamamos al listado
	// 		clearTimeout(idTimer.current)
	// 		idTimer.current = setTimeout(() => {
	// 			setListParams((prev) => ({
	// 				...prev,
	// 				recarga: true
	// 			}))
	// 		}, 1000)
	// 	}
	// 	else {
	// 		clearTimeout(idTimer.current)
	// 		// setList([])
	// 	}
	// }, [BProf]);
	// effect de carga de datos inicial
	useEffect(() => {
		async function ver() {
			const pet = await peticion('/cursos/ver?id=' + props.show)
			if (pet.estado)//algún error 
				return;
			setFormu({
				titulo: pet.res.titulo,
				ciudad: pet.res.ciudad,
				obs: pet.res.obs,
				aSesion: pet.res.aSesion,
			})
			setFormuCopy({
				titulo: pet.res.titulo,
				ciudad: pet.res.ciudad,
				obs: pet.res.obs,
				aSesion: pet.res.aSesion,
			})
			// setListParams((prev) => ({
			// 	...prev,
			// 	selec: pet.res.selec,
			// 	anio: pet.res.anio,
			// 	recarga: true
			// }))
			if (pet.res.cfValDojo !== 0) setLectura(true)
		}
		// console.log('props.show',props.show);
		if (props.show > 0)
			ver()
		else if (props.show < 0) {
			setFormu({
				titulo:'',
				fecha: '',
				ciudad: '',
				obs: '',
				aSesion: [{
					fecha: '',
					horaini: '',
					horafin: '',
				}],
			})
			// setAnio('')
			// setNumAd(0)
			// setNumNi(0)
			// setListParams((prev) => ({
			// 	...prev,
			// 	anio: '',
			// }))
			rTitulo.current.focus()
		}
		// eslint-disable-next-line
	}, [props.show])

	// guardar el registro
	async function guarda() {
		const diffKeys = Object.keys(Formu).filter(key => Formu[key] !== FormuCopy[key] && !key.endsWith("Err"));
		// requeridos
		const error = {}
		if (Formu.titulo==='') error.tituloErr=loc('El título es requerido.')
		if (Formu.ciudad==='') error.ciudadErr=loc('La ciudad es requerida.')
		// if (Formu.obs==='') error.obsErr=loc('Debes poner los detalles del curso .')
		// if (Formu.fecha!=='' && !moment(Formu.fecha).isAfter(moment())) error.fechaErr=loc('La fecha debe ser futura.')
		if (Formu.aSesion.length<1) error.nSesionesErr=loc('Debe haber al menos una sesion')
		let err=''
		Formu.aSesion.forEach(el=>{
			if (el.fecha===''|el.horaini===''|el.horafin==='')
				err=loc('La fecha, hora de inicio y hora de fin de la sesión es obligatoria')
		})
		if (err!=='') error.sesionesErr=err

		// filtros sin necesidad de peticiones
		if (Object.keys(error).length > 0) {//algún error
			Object.assign(Formu, error)
			setFormu({ ...Formu })
			return;
		}
		let pet
		if (props.show < 0)//inserción
			pet = await peticion('/cursos/ins', { method: 'POST', json: {
				ciudad: Formu.ciudad,
				titulo: Formu.titulo,
				obs: Formu.obs,
				aSesion: Formu.aSesion
			} })
		else if (props.show > 0)//modificación
			pet = await peticion('/cursos/mod', {
				method: 'POST', json: {
					modificar: diffKeys,
					id: props.show,
					ciudad: Formu.ciudad,
					titulo: Formu.titulo,
					obs: Formu.obs,
					aSesion: Formu.aSesion
				}
			})
		let id = pet?.res?.idCurso ?? props.show
		if (pet.estado)//algún error 
			return;
		cierra()
		if (parseInt(id) === props.ListParams.idReg)
			props.setListParams((prev) => ({ ...prev }))
		else {
			navigate(`/${lang}/cursos`)
			props.listar()
		}

	}
	//cierra el modal
	function cierra() {
		setFormu({ ...Formu, fechaErr: '', ciudadErr: '' })
		props.onHide()
	}

	// Incrementa o decrmenta la cantidad de sesiones
	function incrSesion(incr) {
		if (incr > 0) {
			Formu.aSesion.push({
				fecha: Formu.fecha,
				horaini: '',
				horafin: '',
			});
		} else if (Formu.aSesion.length > 1) {
			Formu.aSesion.pop();
		} else {
			return;
		}
		// setNumSesiones(Formu.aSesion.length);
		setFormu({ ...Formu, aSesion: Formu.aSesion, sesionesErr: '', nSesionesErr: '' });
	}
	
	//orden subformulario
	// function orden(campo) {
	// 	if (ListParams.orden === campo)
	// 		campo = campo + ' DESC'
	// 	setListParams({
	// 		...ListParams,
	// 		orden: campo,
	// 		recarga: true
	// 	})
	// }

	if (props.show === 0)
		return ("")
	else
		return (
			<main className="modal-wrapper">
				<div className="modal-container backdrop content-wrapper">
					<div className='modal-header'>
						<div className='flex-wrapper'>
							<CursoIcon className='section-icon curso' />
							<h3>{props.show > 0 ? loc('Modificar curso') : loc('Nuevo curso')}</h3>
						</div>
						<CloseIcon onClick={cierra} className='modal_close-icon' alt='Cerrar ventana' title='(ESC)' />
					</div>
					<div className='modal-content-scroll form-container'>
						<div>
							<p>{loc('Título')}*</p>
							<input
								type='text'
								style={{ width: '100%' }}
								value={Formu?.titulo?? ''}
								ref={rTitulo}
								onChange={(self) => { setFormu({ ...Formu, titulo: self.target.value, tituloErr: '' }) }}
								placeholder={loc('Título')} />
							<p className='error'>{Formu.tituloErr}</p>
						</div>
						<div className='flex-wrapper flex-grow underline'>
							{/* <div>
								<p>{loc('Fecha')}*</p>
								<input type="date"
									value={Formu?.fecha ?? ''}
									ref={rFecha}
									onChange={(self) => { setFormu({ ...Formu, fecha: self.target.value, fechaErr: '' }) }} />
								<p className='error'>{Formu.fechaErr}</p>
							</div> */}
							<div>
								<p>{loc('Ciudad')}*</p>
								<input
									value={Formu?.ciudad ?? ''}
									onChange={(self) => { setFormu({ ...Formu, ciudad: self.target.value, ciudadErr: '' }) }}
									placeholder={loc('Ciudad')} />
								<p className='error'>{Formu.ciudadErr}</p>
							</div>
							<div>
								<p>{loc('Número de sesiones')}*</p>
								<div className='flex-wrapper'>
									<button className='button primary' onClick={() => incrSesion(-1)}>-</button>
									<input className='input-calculated'
										disabled={true}
										value={(Formu?.aSesion ?? []).length} style={{ textAlign: 'right' }}
										placeholder={loc('Sesiones')} />
									<button className='button primary' onClick={() => incrSesion(1)}>+</button>
								</div>
								<p className='error'>{Formu.nSesionesErr}</p>
							</div>
						</div>

						{/* <div className="flex-wrapper">
							<button disabled >{loc('Fecha')}</button>
							<button disabled >{loc('Hora inicio')}</button>
							<button disabled >{loc('Hora fin')}</button>
						</div> */}
						<div className='cards-wrapper'>
							{(Formu?.aSesion ?? []).map((el, i) => (
								<Fragment key={i}>
									<div className='single-card-container'>
										<h4>{loc('Sesión #') + (i+1)}</h4>
										<span>{loc('Fecha')}</span>
										<div>
											<input type="date"
												value={el.fecha || ''}
												onChange={(self) => { Formu.aSesion[i].fecha = self.target.value; setFormu({ ...Formu, aSesion: Formu.aSesion, sesionesErr: '' }) }}
											/>
										</div>
										<span>{loc('Hora de inicio')}</span>
										<div>
											<input type='time'
												value={el.horaini || ''}
												onChange={(self) => { Formu.aSesion[i].horaini = self.target.value; setFormu({ ...Formu, aSesion: Formu.aSesion, sesionesErr: '' }) }}
											/>
										</div>
										<span>{loc('Hora de fin')}</span>
										<div>
											<input type='time'
												value={el.horafin || ''}
												onChange={(self) => { Formu.aSesion[i].horafin = self.target.value; setFormu({ ...Formu, aSesion: Formu.aSesion, sesionesErr: '' }) }}
											/>
										</div>
									</div>
								</Fragment>
							))}
						</div>

						<p className='error continput'>{Formu.sesionesErr}</p>

						<div>
							<p>{loc('Detalles')}</p>

							<textarea
								rows="5"
								style={{ width: '100%' }}
								value={Formu.obs}
								onChange={(self) => { setFormu({ ...Formu, obs: self.target.value, obsErr: '' }) }}
								placeholder={loc('Detalles del curso. Profesores, recomendaciones, posibles alojamientos...')} />

							<p className='error'>
								{Formu.obsErr}
							</p>
						</div>
					</div>
					{/* <div className="col-xs-12 continput" >
					<p>{loc('Profesores')}</p>
					<input
						value={Formu.profs}
						disabled={true}
						placeholder={loc('Busca los profesores y seleccionalos')} />
					<p className='error'> </p>
				</div>
				<div className="col-xs-12 continput" >
					<p>{loc('Buscar profesor')}</p>
					<input
						onChange={(self) => {setBProf(self.target.value)}}
						placeholder={loc('Nombre profesor')} />
					<p className='error'> </p>
				</div>
			{Cargando?(
						<div className="col-xs-12">
							<div className="precarga"><img src={loading} alt="cargando"/></div>
						</div>
					):(

				<div className="contresto col-xs-12" >

				<div className="col-xs-12 ">

					<div className='col-xs-12 divtabla'>
						<table border="0" className="tablaejem tabladatos" style={{ borderCollapse: "collapse" }}>
							<thead>
								<tr key="cabeceradatostabla" style={{ width: '100%' }}>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('numAcae')}>{loc('Núm. ACAE')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'numAcae' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'numAcae DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '20%' }}>
										<button onClick={() => orden('nombre')}>{loc('Nombre')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'nombre' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'nombre DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '40%' }}>
										<button onClick={() => orden('apellido')}>{loc('Apellidos')}</button>
										<ul className="listaordena">
											{ListParams.orden === 'apellido' &&
												<li><img src={asc} alt='ordena ascendente' /></li>
											}
											{ListParams.orden === 'apellido DESC' &&
												<li><img src={desc} alt='ordena descendente' /></li>
											}
										</ul>
									</th>
									<th style={{ width: '10%',textAlign:'right' }}>
										<button disabled onClick={() => orden('numAcae')}>{loc('Selecciona')}</button>
									</th>
								</tr>
							</thead>
						</table>
					</div>
				</div>
				<div className="col-xs-12 scroll">
					<table border="0" className="tablaresultados" style={{ borderCollapse: "collapse" }}>
						<tbody>
							{List.map((elem) => ((Lectura & !ListParams.selec.includes(elem.idAso))?null :
								<tr key={elem.idAso} className={'alumn'+(elem.selec?'':(ListParams.selec.includes(elem.idAso)?'Act':'Des'))} onClick={()=>{if (!elem.selec) toggleSelec(elem.idAso)}}>
									<td style={{ width: '20%' }}>
										<img src={arrow} alt='arrow' className='arrow'/>
										<div className="photo_row">
											<img src={imgAvatar(elem.idAso)} alt="fotoperfil" />
										</div>
										<span style={{paddingLeft:'25px'}}>{elem.numAcae}</span>
									</td>
									<td style={{ width: '20%' }}>{elem.nombre}</td>
									<td style={{ width: '40%' }}>{elem.apellido}</td>
									<td style={{ width: '10%' }}>
										<div  className="contpin">
											{elem.selec? loc('Ya remesado') : ListParams.selec.includes(elem.idAso)?
												<img src={pin} alt="" className='imgpin'/>
												:
												<img src={nopin} alt="" className='imgpin'/>
											}
										</div>
									</td>
								</tr>
							))}
							
						</tbody>
					</table>
					

				</div>
				<div className="col-xs-12 ">
					<p className='error'>{ErrList}</p>
				</div> 
				</div> 
					)} */}


					<div className='modal-footer'>
						<div className='flex-wrapper flex-end'>
							<button type="button" onClick={guarda} className="button primary ">{Lectura?(loc('Guardar')):(loc('Aplicar'))}
								<GuardarIcon alt='Aplicar' title='Aplicar' />
							</button>
							<button
								className="button tertiary"
								onClick={cierra}
							>
								{loc('Cancelar')}
							</button>
						</div>
					</div>
				</div>
			</main>

		);
}

export default CursosMod;