import React, { useContext, useEffect, useCallback, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";

import ConexContext from "./context/conex/ConexContext";

// Componentes
import Login from "./components/Login";
import Dojos from "./components/Dojos";
import Layout from "./components/Layout";
import Asocs from "./components/Asocs";
import Asociados from "./components/Asociados";
import AsociadosVista from "./components/AsociadosVista";
import { Inicio } from "./components/Inicio";
import Cuotas from "./components/Cuotas";
import Validaciones from "./components/Validaciones";
import Cursos from "./components/Cursos";
import Listado from "./components/Listado";

function App() {
    const { token, setToken, perfil_adm_dojo, perfil_admin, perfil_adm_asoc, peticion } = useContext(ConexContext);
    const { lang } = useParams();
    // Estados
    const MaxTimer = 1860;
    const [WCuotas, setWCuotas] = useState(0);
    const [Sidebar, setSidebar] = useState(false);
    const [Timer, setTimer] = useState(-1);

    const guaLocalToken = useCallback(() => {
        if ('token' in localStorage && localStorage.token) {
            setToken(localStorage.token);
        }
    }, [setToken]);

    useEffect(() => {
        if (!token) guaLocalToken();
    }, [token, guaLocalToken]);

    useEffect(() => {
        const warning = async () => {
            const pet = await peticion('/cuotas/warnings');
            if (pet?.estado ?? false) setTimer(-1);
            setWCuotas(pet?.res ?? 0);
        };
        if (Timer === 0) {
            if (perfil_adm_asoc || perfil_admin) {
                warning();
            } else {
                setWCuotas(0);
            }
        }
    }, [peticion, perfil_adm_asoc, perfil_admin, Timer]);

    useEffect(() => {
        if (Timer >= 0) {
            const tp = setTimeout(() => {
                let t = Timer;
                if (t === 0) t = MaxTimer;
                setTimer(t - 1);
            }, 1000);
            return () => clearTimeout(tp);
        }
    }, [Timer]);

    const mostrarCuotas = perfil_adm_dojo || perfil_admin || perfil_adm_asoc;

    // Detectar dispositivos móviles
    const isMobile = window.innerWidth <= 768;

    if (!token) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/es" />} exact />
                    <Route path="/:lang/*" element={<Login />} />
                </Routes>
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to="/es" />} exact />
                    <Route path="/:lang" element={<Layout WCuotas={WCuotas} setTimer={setTimer} Sidebar={Sidebar} setSidebar={setSidebar}/>}>
                        <Route index element={<Inicio Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        <Route path="/:lang/inicio" element={<Inicio Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        <Route path="/:lang/dojos/:asoc">
                            <Route index element={<Dojos Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                            <Route path=":idReg" element={<Dojos Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        </Route>
                        <Route path="/:lang/asociaciones" element={<Asocs Sidebar={Sidebar} setSidebar={setSidebar}/>}>
                            <Route path=":idReg" element={<Asocs Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        </Route>
                        <Route path="/:lang/asociados" element={<Navigate to={`/${lang}/asociados/dojo_`} />} />
                        <Route path="/:lang/asociados/:dojo" element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar}/>}>
                            <Route path=":idReg" element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar}/>}>
								<Route path=":acc" element={<Asociados Sidebar={Sidebar} setSidebar={setSidebar}/>} />
							</Route>
                        </Route>
                        <Route path="/:lang/asociados/usuario/:numAcae" element={<AsociadosVista Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        {mostrarCuotas && (
                            <Route path="/:lang/cuotas" element={<Cuotas setTimer={setTimer} Sidebar={Sidebar} setSidebar={setSidebar}/>}>
                                <Route path=":idReg" element={<Cuotas setTimer={setTimer} Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                            </Route>
                        )}
                        <Route path="/:lang/validaciones" element={<Validaciones />}>
                            <Route path=":idReg" element={<Validaciones />} />
                        </Route>
                        <Route path="/:lang/cursos" element={<Cursos Sidebar={Sidebar} setSidebar={setSidebar}/>}>
                            <Route path=":idReg" element={<Cursos Sidebar={Sidebar} setSidebar={setSidebar}/>} />
                        </Route>
                        <Route path="/:lang/listado" element={!isMobile ? <Listado /> : <Navigate to="/es/inicio" />}>
                            <Route path=":idReg" element={!isMobile ? <Listado /> : <Navigate to="/es/inicio" />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;
